@import '../../../../styles//variable.scss';

#scrollCustom {
  overflow: scroll;
  overflow-x: unset;
  height: 100vh;
}

#scrollCustom::-webkit-scrollbar {
  width: 8px;
}

#scrollCustom.thin::-webkit-scrollbar {
  width: 2px;
}

#scrollCustom::-webkit-scrollbar-track {
  border-radius: 10px;
  background: $color-white;
}

#scrollCustom::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: $bg-yellow1;
}
