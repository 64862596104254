@import 'styles/variable.scss';
@import "../../../../../src/styles/mixin";
.list-overtime {
  margin-bottom: 100px;
  @include min-width-1200;
  .table-list {
    border: unset;
  }
  .button {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    height: 40px;
    margin-top: -5px;
  }
  .error-no-data {
    text-align: center;
    color: $color-red;
    padding: 20px 0;
  }
  .profile-box {
    margin-top: 20px;
    cursor: pointer;
    padding: 0;
    box-shadow: 0px 5px 25px $color-black2;
    border-radius: 5px;
    .project-date {
      width: 18%;
    }
    .short {
      width: 8%;
    }
    .asset-header {
      color: $color-black;
      line-height: 2rem;
      font-size: 0.9rem;
      font-weight: bold;
      cursor: auto;
    }
    .asset-header-row {
      background-color: $color-headTable !important;
    }
    :global {
      .MuiTableCell-root {
        text-align: left;
      }
    }
    .asset-header tr {
      background-color: $color-headTable !important;
    }
    tr:nth-child(odd) {
      background-color: $bg-white;
    }
    tr:nth-child(even) {
      background-color: $bg-tableStripped;
    }
    :global {
      .MuiTableCell-root {
        white-space: normal !important;
        word-wrap: break-word !important;
      }
      .css-15wwp11-MuiTableHead-root {
        .MuiTableCell-root {
          color: $color-titleBody;
          font-weight: 500;
        }
      }
    }
    table {
      margin: 0;
      table-layout: fixed;
      .profile-content-info {
        word-wrap: break-word;
        font-weight: normal;
      }

      .thead {
        background-color: $color-headTable;

        .profile-content-info {
          color: $color-titleBody;
        }
      }

      tr {
        font-weight: 500;
        color: $color-charcoal;
      }
    }
    @media only screen and (max-width: 1365px) {
      .table-scroll {
        overflow-x: scroll;
        cursor: pointer;
        width: 130%;
      }
    }
    .scroll-custom::-webkit-scrollbar {
      width: 2px;
      height: 5px;
    }
    .scroll-custom::-webkit-scrollbar {
      width: 2px;
    }
    .scroll-custom::-webkit-scrollbar-track {
      border-radius: 10px;
      background: $color-white;
    }
    .scroll-custom::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: $color-navActive;
    }
    .create-button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 17px 0;
      height: 30px;
      background: $color-navActive;
      border-radius: 5px;
      color: $color-white;
      width: 95px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;

      .text-button {
        width: 70px !important;
      }

      .img-reset {
        margin: '0 10px 0 0';
      }
      &:hover {
        background: $color-navActive;
        color: $color-white;
      }
      &:active {
        background: $color-blue !important;
        color: $color-white !important;
      }
    }
  }
  .box-filter {
    background: $bg-tooltip;
    margin: 19px 0 0 23px;
    height: auto;
    padding: 15px;
    border: 1px solid $color-wheat;
    box-sizing: border-box;
    box-shadow: 0px 5px 25px $color-black2;
    border-radius: 5px;
    .box-waiting-content.second {
      padding-top: 0;
    }
    .box-waiting-content {
      padding-bottom: 10px;
      display: flex;
      .content {
        padding-top: 18px;
        display: flex;
        width: 95%;
        border-top: 1px solid #e7e7e7;
      }
      img {
        width: 23px;
        height: 23px;
        margin-right: 10px;
      }
      img.total-assets {
        width: 29px !important;
        height: 29px !important;
      }
      .box-waiting-text {
        .waiting-text {
          color: $bg-nav;
          font-weight: normal;
          font-size: 12px;
          line-height: 20px;
        }
        .waiting-number {
          color: $color-menuActive;
          font-weight: 500;
          font-size: 20px;
          line-height: 23px;
        }
      }
    }
    .border-top {
      border-top: 1px solid $color-borderTable;
    }
    .text {
      color: $color-titleBody;
      font-size: 16px;
      padding-bottom: 25px;
    }
    .custom {
      color: $color-titleBody;
      font-size: 16px;
      padding-bottom: 20px;
    }
    .reset {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 22px 32px;
      margin-top: 10px;
      height: 36px;
      background: $color-navActive;
      border-radius: 5px;
      color: $color-white;
      width: 100%;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;

      .img-reset {
        margin: '0 10px 0 0';
      }

      &:hover {
        background: $color-navActive;
        color: $color-white;
      }

      &:active {
        background: $color-blue !important;
        color: $color-white !important;
      }
    }
  }
}
