@import 'styles//variable.scss';

.buttonGroupTop {
  text-align: right;
}

.boxTotal {
  display: flex;
  align-items: flex-start;
  border: 1px solid $color-wheat;
  border-radius: 5px;
  padding: 17px 17px 10px 17px;
  margin-bottom: 10px;
  font-size: 14px;
  color: $color-silver;
  background-color: $bg-white;

  img {
    margin-right: 12px;
    width: 15px;
    height: 15px;
  }
  .number {
    font-size: 20px;
    font-weight: 500;
    color: $color-yellow;
    line-height: 23px;
  }
}

.filterTitle {
  color: $color-navy;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
}

.assets {
  display: grid;
  grid-template-columns: auto minmax(25%, min-content);
  gap: 15px;
  margin-top: 15px;

  &__condition {
    min-width: 270px;
  }

  @media only screen and (max-width: 768px) {
    grid-template-columns: auto;
  }
}
