@import './../../styles/variable.scss';

.boxMenu {
  position: fixed;
  height: 55px;
  width: 100%;
  z-index: 999;
  top: 0;
  ul {
    min-height: 55px;
  }
}
.paddingTop {
  padding-top: 70px;
}

.menu {
  margin-bottom: 20px;
  background-color: #222433;
  :global {
    .navbar {
      padding: 0;

      .nav-link {
        font-weight: 500;
        font-size: 14px;
        padding-top: 0;
        padding-bottom: 0;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }
  .menuLeft {
    :global {
      .navbar-nav {
        .nav-link {
          line-height: 55px;
          color: $color-white;
          &.active {
            background: $bg-menuActive;
            color: $color-menuActive;
            border-bottom: 3px solid $color-menuActive;
            border-radius: 0;
            height: 55px;
            display: inline-flex;
            align-items: center;
          }
        }
      }
    }
  }
  @media screen and (max-width: 992px){
    margin-top: 5px;
  }
}

.logo {
  display: flex;
  height: 30px;

  h4 {
    margin-left: 8px;
    margin-right: 20px;
    margin-bottom: 0;
    font-size: 25px;
    line-height: 32px;
    text-transform: uppercase;
    color: $color-menu !important;
  }
}

.menuInfo {
  position: relative;

  .numberInfo {
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: $bg-menuNumberInfo;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    font-size: 10px;
    line-height: 19px;
    text-align: center;
  }
}

.logOut {
  cursor: pointer;
}

.imgLogout {
  width: 24px;
  height: 24px;
}

@media only screen and (min-width: 992px) {
  .menu {
    :global {
      .navbar-nav {
        // height: 55px;

        .nav-link {
          padding-right: 1rem;
          padding-left: 1rem;
        }
      }

      .ml-auto {
        display: inline-flex;
        align-items: center;
      }
    }
  }
}
@media only screen and (max-width: 1360px) {
  .menu {
    .menuLeft {
      :global {
        .navbar-nav {
          .nav-link {
            line-height: inherit;
            height: 55px;
            display: inline-flex;
            align-items: center;
          }
        }
      }
    }
  }
}
@media screen and (max-width : 900px) {
  .subMenu{
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    float: right;
  }
}