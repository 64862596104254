@import 'styles/variable.scss';
@import './../../../styles/mixin';

.list-assets {
  margin-bottom: 100px;
  margin-top: 20px;
  display: grid;
  grid-template-columns: auto minmax(25%, min-content);
  gap: 15px;

  &__condition {
    min-width: 270px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  @media only screen and (max-width: 768px) {
    grid-template-columns: auto;
  }

  .button {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    height: 40px;
    margin-top: -5px;
  }
  .export {
    margin-left: 10px;
    width: 137px;
  }
  .create {
    margin-left: 10px;
    width: 178px;
  }
  ul {
    list-style: none;
    padding-left: 0;
  }
  .bold {
    font-weight: 500;
  }
  .export {
    margin-left: 10px;
    width: 150px;
  }

  .padding-left {
    padding-left: 10px;
  }

  .error-no-data {
    text-align: center;
    color: $color-red;
    padding: 20px 0;
    font-weight: 100;
  }

  .flex {
    display: flex;
  }

  .center {
    text-align: center;
  }

  .button {
    display: flex;
    justify-content: flex-end;
    height: 40px;
    margin-top: -5px;
  }

  .profile-box {
    cursor: pointer;
    .asset-header {
      color: $color-black;
      line-height: 2rem;
      font-size: 0.9rem;
      font-weight: bold;
      cursor: auto;
    }

    .asset-header-row {
      background-color: $color-headTable !important;
    }

    :global {
      .MuiTableCell-root {
        text-align: left;
      }
    }

    .asset-content {
      color: $color-black;
      line-height: 2rem;
      font-size: 0.9rem;
      font-weight: bold;
      td {
        cursor: pointer;
      }
    }

    .asset-header tr {
      background-color: $color-headTable !important;
    }

    tr:nth-child(odd) {
      background-color: $bg-white;
    }

    tr:nth-child(even) {
      background-color: $bg-tableStripped;
    }
  }

  .profile-box {
    padding: 0;
    box-shadow: 0px 5px 25px $color-black2;
    border-radius: 5px;

    :global {
      .MuiTableCell-root {
        white-space: normal !important;
        word-wrap: break-word !important;
      }
      .css-15wwp11-MuiTableHead-root {
        .MuiTableCell-root {
          color: $color-titleBody;
          font-weight: 500;
        }
      }
    }

    :global {
      .css-o9ey65 {
        position: relative;
        .MuiTablePagination-displayedRows.css-1chpzqh {
          position: absolute;
          left: 17px;
        }

        .MuiTablePagination-actions {
          .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
            color: $color-menuActive;
            font-size: 30px;
          }
        }
      }
    }

    table {
      width: 100%;
      margin: 0;
      table-layout: fixed;

      .profile-content-info {
        word-wrap: break-word;
        font-weight: normal;
      }

      .thead {
        background-color: $color-headTable;

        .profile-content-info {
          color: $color-titleBody;
        }
      }

      tr {
        font-weight: 500;
        color: $color-charcoal;
      }
    }
    .create-button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 17px 0;
      height: 30px;
      background: $color-navActive;
      border-radius: 5px;
      color: $color-white;
      width: 95px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;

      .text-button {
        width: 70px !important;
      }

      .img-reset {
        margin: '0 10px 0 0';
      }
      &:hover {
        background: $color-navActive;
        color: $color-white;
      }
      &:active {
        background: $color-blue !important;
        color: $color-white !important;
      }
    }
  }

  .box-waiting {
    box-sizing: border-box;
    box-shadow: 0px 5px 25px $color-black2;
    border-radius: 5px;
    .box-waiting-content.second {
      padding-top: 0;
    }
    .box-waiting-content {
      padding: 18px 0 15px 20px;
      display: flex;
      .content {
        padding-top: 18px;
        display: flex;
        width: 95%;
        border-top: 1px solid #e7e7e7;
      }
      img {
        width: 23px;
        height: 23px;
        margin-right: 10px;
      }
      img.total-assets {
        width: 29px !important;
        height: 29px !important;
      }
      .box-waiting-text {
        .waiting-text {
          color: $bg-nav;
          font-weight: normal;
          font-size: 12px;
          line-height: 20px;
        }
        .waiting-number {
          color: $color-menuActive;
          font-weight: 500;
          font-size: 20px;
          line-height: 23px;
        }
      }
    }
  }
  .box-filter {
    background: $bg-tooltip;
    height: auto;
    padding: 15px;
    border: 1px solid $color-wheat;
    box-sizing: border-box;
    box-shadow: 0px 5px 25px $color-black2;
    border-radius: 5px;
    .selected {
      background: $color-totalEmployee;
    }
    .not-selected {
      background: $bg-thTable1;
    }
    .select-field {
      border-radius: 5px;
      padding: 15px;
      display: flex;
      position: relative;
      cursor: pointer;
      margin-bottom: 5px;
      .img-filter {
        width: 20px;
        height: 20px;
      }
      .select-field-title {
        margin-left: 15px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: $color-navActive;
        word-wrap: break-word;
      }
      .select-field-total {
        position: absolute;
        right: 17px;
        color: $color-navActive;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
      }
    }
    .padding-element-filter {
      .exampleSelectMulti {
        appearance: none;
        width: 100%;
        cursor: pointer;
        border: 1px solid $color-inputColor;
        box-sizing: border-box;
        border-radius: 5px;
        background-color: $color-white;
        justify-content: center;
        align-items: flex-start;
        padding: 5px 0;
        padding-left: 13px;
        height: auto;
        color: $color-truncate;
      }
      .exampleSelectMulti:hover {
        cursor: pointer;
      }
    }
    .padding-element-top {
      padding-top: 20px;
    }
    .margin-element-bottom {
      margin-bottom: 30px;
    }
    .border-top {
      border-top: 1px solid $color-borderTable;
    }
    .text {
      color: $color-titleBody;
      font-weight: 500px;
      font-size: 16px;
    }
    .division {
      cursor: pointer;
      position: relative;
      width: 100%;
      display: flex;
      .dot-status-accept {
        width: 13px;
        height: 13px;
        border-radius: 13px;
        margin-top: 15px;
      }
      .color-blue {
        background-color: $color-blue1;
      }
      .color-green {
        background-color: $color-active;
      }
      .total {
        position: absolute;
        right: 0px;
        width: auto;
        padding: 6px;
        background: $color-headTable;
        color: $color-titleBody;
        border-radius: 5px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
      }
    }
    .reset {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 22px 32px;
      margin-top: 10px;
      height: 36px;
      background: $color-navActive;
      border-radius: 5px;
      color: $color-white;
      width: 100%;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;

      .img-reset {
        margin: '0 10px 0 0';
      }

      &:hover {
        background: $color-navActive;
        color: $color-white;
      }

      &:active {
        background: $color-blue !important;
        color: $color-white !important;
      }
    }

    li {
      padding-bottom: 25px;
    }
  }
  .link-request {
    color: $color-titleExport;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-decoration: none;
  }
  .email {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin: 0 0 0 0;
    color: $color-email;
  }
  .color-waiting {
    color: $color-blue1;
  }
  .color-deny {
    color: $bg-menuNumberInfo;
  }
  .color-accept {
    color: $color-active;
  }

  .info-detail {
    display: flex;
    padding: 23px 20px 20px 20px;
    background-color: $color-headTable;
    .status-info {
      line-height: 20px;
    }
    .avatar {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
    .info {
      width: 100%;
    }
    .link-request {
      line-height: 18px !important;
    }
    .email {
      line-height: 18px !important;
    }
  }
  .info-profile {
    padding: 15px 25px;
    .info-profile-element {
      padding: 20px 0;
      margin-right: 20px;
      border-bottom: 1px solid $color-borderTable;
      .info-profile-label {
        color: $color-titleBody;
        font-weight: 500;
      }
      .info-profile-content {
        color: $color-charcoal;
        font-weight: normal;
      }
    }
  }
}

.materialStyle {
  &.ant-form {
    &.ant-form-middle {
      @include materialStyle(32px);
    }

    &.ant-form-large {
      @include materialStyle(44px);
    }
  }
}

.cancelButton {
  background-color: #fff8ee !important;
  border: #fff8ee !important;
  color: #fea628 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  height: 40px !important;
  border-radius: 5px !important;
}

.okButton {
  @extend.cancelButton;

  background: #fea628 !important;
  border: none !important;
  color: #ffffff !important;

  &:hover {
    background: #ffbd52 !important;
  }
}

.custom-tooltip {
  .ant-tooltip-inner {
    background-color: #ffffff;
    max-width: 50vw;
    border-radius: 5px;
    color: #333333;
    vertical-align: middle;
    padding: 12px !important;
  }

  .ant-tooltip-arrow::before {
    background-color: #FFFFFF;
  }
}
