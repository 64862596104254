@import '../../../../styles//variable.scss';

.leftComponent {
  background-color: $bg-white;
  border: 1px solid $color-grey;
  border-radius: 5px;
  padding: 0 20px;

  .loading{
    margin-bottom: 10%;
  }
  .division {
    .divisionName {
      color: $color-titleBody;
      font-size: 16px;
      font-weight: 500;
      padding: 20px 0 8px 0;
      border-bottom: 1px solid $color-grey;
    }

    .divisionMember {
      display: flex;
      flex-wrap: wrap;
      margin-top: 30px;

      .member {
        display: flex;
        flex-basis: calc(100% / 3);
        margin-bottom: 30px;

        .avatar {
          width: 63px;
          height: 63px;
          object-fit: cover;
          border-radius: 50%;
          margin-right: 10px;
        }

        .info {
          .name {
            color: $color-titleBody;
            font-weight: 500;
            margin: 3px 0;
          }

          .email,
          .code {
            color: $color-silver;
            margin: 3px 0;
          }
        }
      }
    }
  }
}

.rightComponent {
  .totalMember {
    display: flex;
    align-items: flex-start;
    background-color: $bg-white;
    border: 1px solid $color-grey;
    border-radius: 5px;
    padding: 17.5px;
    margin-bottom: 10px;

    img {
      margin-right: 12.5px;
    }

    .title {
      font-size: 12px;
      color: $color-silver;
    }

    .total {
      font-size: 20px;
      font-weight: 500;
      color: $color-yellow;
    }
  }

  .searchComponent {
    background-color: $bg-white;
    border: 1px solid $color-grey;
    border-radius: 5px;
    padding: 15px;

    .filterTitle {
      color: $color-titleBody;
      font-size: 16px;
      font-weight: 500;
      margin: 15px 0;
    }
  }
}
