@import '../../../../styles//variable.scss';

.leftComponent {
  background-color: $bg-white;
  border-radius: 5px;
  border: 1px solid $color-grey;
  padding: 20px;

  .infoTop {
    text-align: center;
    margin-bottom: 20px;

    img {
      width: 91px;
      height: 91px;
      object-fit: cover;
      border-radius: 50%;
    }

    .name {
      font-size: 20px;
      font-weight: 500;
      color: $color-navy;
    }

    .position {
      font-size: 16px;
      color: $color-nav;
    }
  }

  .infoBottom {
    .label {
      font-weight: 500;
      color: $color-navy;
      margin: 5px 0;
    }

    .linkProfile {
      text-decoration: underline;
    }
  }
}

.rightComponent {
  .infoBlock {
    padding: 0 20px;
    background-color: $bg-white;
    border: 1px solid $color-grey;
    border-radius: 5px;
    margin-bottom: 20px;
    position: relative;

    .header {
      padding: 24px 0 15px 0;
      display: flex;
      border-bottom: 1px solid $color-grey;
      padding-left: 15px;

      img {
        margin-right: 6px;
      }

      .title {
        font-size: 20px;
        color: $color-navy;
      }
    }

    .body {
      .information {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -20px;

        .item {
          display: flex;
          flex-basis: 50%;
          padding: 18px 0;
          padding-left: 40px;

          .label {
            flex-basis: 40%;
            min-width: 130px;
            color: $color-charcoal;
            font-weight: 500;
          }

          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(7),
          &:nth-child(8) {
            background-color: $bg-rowTable;
          }
        }
      }

      .studyBlock {
        padding: 20px 12px 38px 12px;
        position: relative;

        :global {
          .swiper-container {
            margin-left: unset;
            margin-right: unset;
          }

          .swiper-slide {
            margin-right: 20px !important;
          }
        }

        .studyItem {
          height: 115px;
          background-color: $bg-white;
          border: 1px solid $color-grey;
          border-radius: 5px;
          padding: 13px 15px;
          margin-left: 5px;

          .title {
            font-size: 16px;
            font-weight: 500;
            color: $color-navy;
            margin-bottom: 12px;
            display: inline-block;
            width: 90%;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
          }

          .item {
            display: flex;

            img {
              margin-right: 10px;
            }

            div {
              display: inline-block;
              width: 90%;
              white-space: nowrap;
              overflow: hidden !important;
              text-overflow: ellipsis;
            }
          }
        }

        .nextButton {
          width: 25px;
          height: 26px;
          border: 1px solid $color-borderTable;
          border-radius: 100%;
          background-color: $bg-body;
          position: absolute;
          right: -14px;
          top: 40%;
          z-index: 1;

          img {
            width: 21px;
            height: 26px;
            padding: 7px;
          }
        }

        .nextButton:hover {
          transform: scale(1.2);
          cursor: pointer;
        }

        .prevButton {
          width: 25px;
          height: 26px;
          border: 1px solid $color-borderTable;
          border-radius: 100%;
          background-color: $bg-body;
          position: absolute;
          left: -14px;
          top: 40%;
          z-index: 1;

          img {
            width: 21px;
            height: 26px;
            padding: 7px;
          }
        }

        .prevButton:hover {
          transform: scale(1.2);
          cursor: pointer;
        }
      }

      .achievements {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 15px 0;

        .achievementItem {
          display: flex;
          flex-basis: calc(50% - 10px);
          margin: 5px 0;
          padding: 11px 20px;
          border: 1px solid $color-grey;
          border-radius: 5px;


          img {
            margin-right: 22px;
          }

          .name {
            color: $color-charcoal;
            font-weight: 500;
          }

          .time {
            font-size: 12px;
            color: $color-silver;
          }
        }
      }

      .certificate {
        padding: 15px 0;

        .certificateItem {
          .certificateName {
            font-size: 16px;
            color: $color-charcoal;
            font-weight: 500;
            margin-bottom: 15px;
          }

          .certificateData {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .item {
              display: flex;
              align-items: center;
              flex-basis: calc(50% - 10px);
              border: 1px solid $color-grey;
              border-radius: 5px;
              margin: 5px 0;
              margin-bottom: 15px;

              img {
                margin-right: 10px;
                width: 153px;
                height: 86px;
                object-fit: cover;
                border-radius: 5px;
              }

              .name {
                color: $color-charcoal;
                font-weight: 500;
              }

              .time {
                font-size: 12px;
                color: $color-silver;
              }
            }
          }
        }
      }

      .stars {
        padding: 15px 0 20px 0;
        display: flex;

        .item {
          padding: 6px 12px;
          margin: 5px 10px;
          color: $color-navy;
          background-color: $bg-yellow2;
          border-radius: 16px;
        }
      }
    }
  }
}