@import "../../styles/variable.scss";

.swiperBtnPrev {
  z-index: 1;
  position: absolute;
  top: 70px;
  left: -30px;
  width: 25px;
  height: 26px;
  border: 1px solid $color-borderTable;
  border-radius: 100%;
  background-color: $bg-body;

  img {
    width: 21px;
    height: 26px;
    padding: 7px;
    position: absolute;
    top: 0px;
    right: 0px;
  }

  &:hover {
    transform: scale(1.2);
    cursor: pointer;
  }
}

.swiperBtnNext {
  z-index: 1;
  position: absolute;
  width: 25px;
  height: 26px;
  top: 70px;
  right: -28px;
  border: 1px solid $color-borderTable;
  border-radius: 100%;
  background-color: $bg-body;

  img {
    width: 21px;
    height: 26px;
    padding: 7px;
    position: absolute;
    top: 0px;
    left: 0px;
  }

  &:hover {
    transform: scale(1.2);
    cursor: pointer;
  }
}
