.cardReport {
  border-radius: 10px !important;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 5px 25px 0px rgba(34, 51, 84, 0.15);
  font-size: 14px !important;
  font-family: Roboto;
  margin-top: 15px !important;

  :global(.ant-card-head) {
    border: none !important;
  }

  .infoContainer {
    display: flex;
    gap: 0.625rem;
    color: #223354;

    .infoIcon {
      display: inline;
    }

    .infoTitle {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
    }
  }

  .btnCreate {
    border: none;
    outline: none;
    background-color: #fea628;
    color: #fff;
    width: 150px;
    height: 41px;
    border-radius: 5px;
    font-weight: 500 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    box-shadow: none;
    text-shadow: none;

    &:hover {
      background-color: #ffbd52;
    }

    &:focus {
      border: none;
      background: #fea628;
    }

    svg {
      font-size: 20px;
    }
  }

  :global(.ant-divider-horizontal) {
    margin: 0 !important;
    margin-bottom: 15px !important;
    border-top: 0.5px solid #e7e7e7;
  }

  :global(.ant-card-body) {
    padding-top: 0;
  }

  .tableReport {
    border: 1px solid #e7e7e7;
    border-radius: 8px;

    :global(.ant-table) {
      border-radius: 8px;
      overflow: hidden;

      :global(.ant-table-thead) {
        :global(.ant-table-cell) {
          color: #223354;
          font-weight: 500;
          background-color: #e9ebee;
        }
      }

      :global(.ant-table-tbody) {
        :global(.ant-table-row):nth-child(odd) {
          :global(.ant-table-cell) {
            background-color: #f9f9f9;
          }
        }

        :global(.ant-table-row):hover .action .action__content {
          display: block;
          width: 120px;
        }
      }

      :global(.ant-table-footer) {
        background-color: #ffffff;
      }

      :global(.action-overlay) {
        padding: 5px;
      }

      .action {
        padding: 0;

        &__content {
          position: absolute;
          right: 0;
          width: 0;
          overflow: hidden;
          transition: width 0.3s;
        }
      }
    }

    tbody > tr:hover > td {
      background: rgba(235, 235, 235, 1) !important;
    }

    .btnDelete {
      fill: #8d8d8d;
      margin-bottom: 2.4px 0;
      cursor: pointer;

      &:hover {
        fill: #ffbd52;
      }
    }

    .disableDelete {
      @extend .btnDelete;
      cursor: not-allowed;
    }

    :global {
      .ant-btn {
        border: none;
        color: #8d8d8d;
        background-color: transparent;
        box-shadow: none;
      }

      .ant-btn:hover {
        color: #fea628;
        background-color: #ebebeb;
      }

      .ant-tag {
        padding: 3px 8px;
        width: 90px;
        font-size: 14px;
        text-align: center;
        margin-right: 0 !important;
      }

      .ant-table-thead
        > tr
        > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        display: none;
      }

      .ant-table-cell {
        color: #333;
      }
    }
  }
}

.textOver1Lines {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.minWidthDescription {
  min-width: 300px;
}

.cursorNoDrop {
  cursor: no-drop;
  border: none;
  background-color: transparent;
}
.opacity50 {
  opacity: 0.5 !important;
  border: none;
  background-color: transparent;
}
.cursorPointer {
  cursor: pointer;
  border: none;
  background: transparent;
  display: block;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 25px;

  span {
    color: #8d8d8d;
    font-size: 14px;
    font-weight: 400;
  }

  .color {
    background-color: 'red';
  }

  :global(.ant-select-selector) {
    box-shadow: none !important;
    border: none !important;
  }
}

.textOverFlow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.loading {
  border-top: 1px solid #e7e7e7;
  padding-top: 15px;
  display: flex;
  justify-content: center;
}

:global(.ant-tooltip) {
  max-width: 500px !important;
}

.noData {
  color: #333;
  text-align: center;
  font-size: 16px;
}

.modal {
  width: 653px !important;
  padding-inline: 20px;
  font-family: 'Roboto', sans-serif;

  :global(.ant-modal-content) {
    padding: 0;
    border-radius: 5px !important;
    overflow: hidden;

    :global(.ant-modal-close) {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #223354;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      top: 10px;
      right: 15px;
      align-items: center;

      :global(.ant-modal-close-x) {
        font-size: 18px;
        color: #fea628;
        line-height: 17px;
        text-align: center;
        width: unset;
        height: unset;
      }
    }

    :global(.ant-modal-header) {
      padding: 13px 20px;
      background-color: #222433;

      :global(.ant-modal-title) {
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-weight: 500;
        color: white;
      }
    }

    :global(.ant-modal-body) {
      padding: 20px;
      max-height: calc(100vh - 250px);
      overflow: auto;
    }

    :global(.ant-modal-footer) {
      padding: 20px;
      border: none;
      margin-top: 0;
      display: flex;
      gap: 10px;

      & > button {
        flex: 1;
        min-height: 36px;
      }
    }
  }
}

.dialog {
  font-family: 'Roboto', sans-serif;
  :global(.ant-modal-content) {
    padding: 20px !important;
  }

  :global(.ant-modal-confirm-content) {
    width: -webkit-fill-available;
  }
  :global(.ant-modal-content) {
    border-radius: 4px !important;
  }
  :global(.ant-modal-body) {
    padding: 0 !important;
  }

  .btn {
    font-size: 14px;
    font-weight: 500;
    color: #8d8d8d;
    min-width: 0;
    border: none;

    &:hover {
      color: #8d8d8d;
    }

    &__ok {
      color: #fea628;

      &:hover {
        color: #fea628;
      }
    }
  }
}

.detailReport {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;

  .rejectReason {
    display: flex;
    padding: 16px 12px;
    border-radius: 5px;
    background-color: #ffe7c3;

    span {
      color: #353535;
      font-weight: 500;
      width: 20%;
    }

    p {
      width: 80%;
      color: #393939;
      margin: 0;
    }
  }

  .detailRow {
    display: flex;

    .title {
      width: 25%;
      color: #333;
      font-weight: 500;
    }

    .content {
      width: 75%;
      text-align: justify;
      color: #333;

      a {
        color: #2d99ff;
      }
    }
  }
}

.detailModal {
  .deleteButton {
    gap: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff8ee !important;
    border: #fff8ee !important;
    color: #fea628 !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    height: 40px !important;
    border-radius: 5px !important;
  }

  .editButton {
    @extend .deleteButton;

    background: #fea628 !important;
    border: none !important;
    color: #ffffff !important;

    &:hover {
      background: #ffbd52 !important;
    }
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #ffbd52;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #fea628;
  }
}
