@import '../../../../styles/variable.scss';

.cardReport {
  border-radius: 10px !important;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 5px 25px 0px rgba(34, 51, 84, 0.15);
  font-size: 14px !important;
  font-family: Roboto;
  margin-top: 15px !important;
  min-height: 720px;
  min-width: 900px;

  :global(.ant-card-head) {
    border: none;
  }

  .infoContainer {
    display: flex;
    gap: 0.625rem;

    .infoIcon {
      display: inline;
    }

    .infoTitle {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #223354;
    }
  }

  .extraCalendar {
    gap: 20px !important;

    .fullWorkHour {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #66d997;
      margin-right: 6px;
    }

    .notFullWorkHour {
      @extend .fullWorkHour;
      background-color: #f9e2c0;
    }

    .notReport {
      @extend .fullWorkHour;
      background-color: #ffc6c6;
    }

    .btnCreate {
      border: none;
      outline: none;
      background-color: #fea628;
      color: #fff;
      width: 150px;
      height: 40px;
      border-radius: 5px;
      padding: 0px 10px !important;
      font-weight: 500 !important;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      box-shadow: none;
      text-shadow: none;

      &:hover {
        background-color: #ffbd52;
      }

      &:focus {
        border: none;
        background: #fea628;
      }

      svg {
        font-size: 20px;
      }
    }
  }

  :global(.ant-divider-horizontal) {
    margin: 0 !important;
    margin-bottom: 15px !important;
    border-top: 0.5px solid #e7e7e7;
  }

  :global(.ant-card-body) {
    padding-top: 0;
  }
}

.filterMonth {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  gap: 7px;

  .month {
    color: #223354;
    font-weight: 500;
    line-height: 20px;
    padding: 0 17px;
  }

  .preBtn,
  .nextBtn {
    border: none;
    height: 30px;
    width: 30px;
    margin: 0 2px;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
  }
}

.calendar {
  margin-top: -9px;
  .calendarHeader {
    border-radius: 4px;
    overflow: hidden;

    .top {
      display: flex;
      align-items: center;
      background-color: $bg-salmon;
      padding: 6px 17.5px;
      border-radius: 4px 4px 0 0;

      @mixin icon {
        padding: 6px;
        border-radius: 50%;
      }
    }

    .bottom {
      display: flex;
      background-color: $bg-white;
      border-radius: 5px;
      overflow: hidden;
      border: 1px solid #e9ebee;

      .weekDay {
        padding: 4px;
        text-align: center;
        flex-basis: calc(100% / 7);
        font-weight: 500;
      }

      .weekDay:nth-child(2n) {
        border-right: 1px solid #e9ebee;
        border-left: 1px solid #e9ebee;
      }
    }
  }

  .calendarBody {
    display: flex;
    flex-wrap: wrap;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 4px;
    border: 1px solid #e9ebee;

    .date {
      min-height: 128px;
      flex-basis: calc(100% / 7);
      background-color: $bg-white;
      position: relative;
      padding: 12px 8px 4px 8px;
      padding-left: 0;
      display: flex;
      justify-content: space-between;
      border: 1px solid #e9ebee;
      color: #333;
    }

    .actionDate {
      cursor: pointer;
    }

    .today {
      .dateTime {
        color: #fea628;
        font-weight: 500;
      }
    }

    .enoughTime {
      background-color: #bbfdb9;
    }

    .notEnoughTime {
      background-color: #f9e2c0;
    }
    .notReport {
      background-color: #ffc6c6;
    }

    .pastDay {
      background-color: #f7f7f7;
    }

    .holiday {
      background-color: #f7f7f7;
    }

    .borderLeft {
      width: 3px;
      min-height: 130px;
      background-color: #e74c3c;
      border: none;
      margin: -12px 0 -4px 0;
      margin-right: 5px;
    }

    .borderLeftSuccess {
      background-color: #66d997;
    }

    .borderLeftWarning {
      background-color: #fea628;
    }

    .content {
      flex: 1;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      color: #333;
      padding: 0px 4px;

      .timeAndTask {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .task {
          font-weight: 500;
        }
      }

      .statusContent {
        .status {
          flex: 1;
          line-height: 21px;
        }

        .approved,
        .pending,
        .rejected {
          display: flex;
          align-items: center;
          gap: 4px;
        }

        .timeReport {
          font-weight: 500;
        }

        .pending {
          margin: 4px 0;
        }
      }
    }

    .normalDate {
      padding-inline: 12px 4px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    .infoReport {
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }
    .tasks,
    .actualTime {
      line-height: 21px;
    }

    .overTimeTag {
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      padding-inline: 8px;
      border-radius: 20px;
      border: none;
      font-weight: 500;
      color: #fea628;
      background-color: #fff8ee;
    }
  }
}

.detailReport {
  .reportDate {
    font-size: 16px;
    font-weight: 500;
    color: #223354;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #ffbd52;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #fea628;
  }

  .overTimeDate {
    @extend .reportDate;
  }
}

.modal {
  width: 1035px !important;
  padding-inline: 20px;
  font-family: 'Roboto', sans-serif;

  :global(.ant-modal-content) {
    padding: 0;
    border-radius: 5px !important;
    overflow: hidden;

    :global(.ant-modal-close) {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #223354;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      top: 10px;
      right: 15px;
      align-items: center;

      :global(.ant-modal-close-x) {
        font-size: 18px;
        color: #fea628;
        line-height: 17px;
        text-align: center;
        width: unset;
        height: unset;
      }
    }

    :global(.ant-modal-header) {
      padding: 13px 20px;
      background-color: #222433;

      :global(.ant-modal-title) {
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-weight: 500;
        color: white;
      }
    }

    :global(.ant-modal-body) {
      padding: 20px;
      max-height: calc(100vh - 250px);
      overflow: auto;
    }

    :global(.ant-modal-footer) {
      padding: 20px;
      border: none;
      margin-top: 0;
      display: flex;
      gap: 10px;

      & > button {
        flex: 1;
        min-height: 36px;
      }
    }
  }
}

.detailTable {
  border: 1px solid #e7e7e7;
  border-radius: 8px;
  margin-top: 12px;

  :global(.ant-table) {
    border-radius: 8px;
    overflow: hidden;

    :global(.ant-table-thead) {
      :global(.ant-table-cell) {
        color: #223354;
        font-weight: 500;
        background-color: #e9ebee;
      }
    }

    :global(.ant-table-thead
        > tr
        > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before) {
      display: none;
    }

    :global(.ant-table-tbody) {
      :global(.ant-table-row):nth-child(odd) {
        :global(.ant-table-cell) {
          background-color: #f9f9f9;
        }
      }

      :global(.ant-table-row) {
        :global(.ant-table-cell) {
          color: #333;
          border: none;
          padding: 11px 16px;
        }
      }
    }

    :global(.ant-table-footer) {
      background-color: #ffffff;
    }

    :global(.action-overlay) {
      padding: 5px;
    }

    .action {
      padding: 0;

      &__content {
        position: absolute;
        right: 0;
        width: 0;
        overflow: hidden;
        transition: width 0.3s;
      }
    }
  }

  tbody > tr:hover > td {
    background: rgba(235, 235, 235, 1) !important;
  }
}

.noData {
  color: #333;
  text-align: center;
  font-size: 16px;
}
