@import './../../styles/variable.scss';
.profileContentInfo {
  color: $color-black;
  line-height: 2rem;
  font-size: 0.9rem;
  font-weight: bold;
  font-family: Roboto;
}

.profileContentInfoAlert {
  color: $color-black;
  line-height: 2rem;
  font-size: 0.9rem;
  font-weight: bold;
  font-family: Roboto;
  padding-top: 10px;
}

.avatarBox {
  font-family: Roboto;
  width: 100%;
  height: auto;
  padding: 1.5em 1.5em 1.25em 1.5em;
  background-color: white;
  border-radius: 5px;
  color: black;
  border: 1px solid $color-borderTable;
  box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);

  .avatarImg {
    text-align: center;
    margin: auto;
    list-style: 22px;
    cursor: pointer;
    width: 6vw;
    height: 6vw;
    overflow: hidden;
    border-radius: 100%;
    position: relative;
  }

  .avatar {
    width: auto;
    height: 100%;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .avatarName {
    width: 100%;
    padding: 5px 0;
    text-align: center;
    line-height: 22px;
    font-weight: bold;
    color: $color-titleBody;
    font-size: 1.25rem;
  }

  .avatarPosition {
    padding: 5px 0;
    text-align: center;
    line-height: 22px;
    font-size: 1rem;
    color: $color-silver;
  }

  .avatarComplete {
    margin-top: 10px;
  }

  .avatarBar {
    display: flex;
    color: $color-green;
    font-weight: bold;
  }

  .avatarContent {
    font-weight: bold;
    padding: 5px 0;
    line-height: 22px;
    font-size: 14px;
    color: $color-titleBody;
  }

  .avatarContentText {
    font-weight: 500;
    padding: 5px 0;
    line-height: 22px;
    font-size: 14px;
    color: $color-titleBody;
    word-break: break-all;
  }

  .avatarManager {
    font-weight: 500;
    padding: 5px 0;
    line-height: 22px;
    font-size: 14px;
    color: $color-managerNameBox;
    text-decoration: underline;
    cursor: pointer;
  }

  .firstLineBox {
    display: flex;
    padding-top: 15px;

    @media only screen and (max-width: 1200px) {
      display: initial;
    }
  }

  .miniBox {
    width: 50%;
    height: auto;
    text-align: center;
    padding: 20px 0;
    border-radius: 5px;
    color: $color-titleBody;
    margin: 5px 0;

    div {
      width: 100%;
      font-weight: 500;
    }
    div:first-child {
      font-size: 34px;
      line-height: 40px;
    }
    @media only screen and (max-width: 1200px) {
      width: 100%;
    }
  }

  .miniBox:first-child {
    background-color: #a6d0f8;
    margin-right: 5px;

    @media only screen and (max-width: 1200px) {
      margin-right: 0px;
    }
  }

  .miniBox:nth-child(2) {
    background-color: #d2dce6;
    margin-left: 5px;

    @media only screen and (max-width: 1200px) {
      margin-left: 0px;
    }
  }

  .miniBox2 {
    width: 100%;
    height: 82px;
    background-color: #ffdba9;
    border-radius: 5px;
    color: $color-titleBody;
    margin: 5px 0;

    display: flex;

    div {
      position: relative;
      img:last-child {
        position: absolute;
        left: 15%;
        top: 15px;
        width: 50px;
        height: auto;
      }
    }
    .OTBox {
      font-size: 34px;
      line-height: 40px;
    }

    img {
      border-radius: 5px 0 0 5px;
      width: 100%;
      height: 100%;
    }

    div:first-child {
      font-size: 34px;
      line-height: 40px;
      width: 30%;
    }
    div:nth-child(2) {
      font-size: 34px;
      line-height: 40px;
      margin: 25px 0;
    }
    div:nth-child(3) {
      margin: 33px 0;
      margin-left: 20px;
      font-weight: normal;
      color: $color-black;
    }
  }

  .miniBox3 {
    width: 100%;
    height: auto;
    background-color: #ff9595;
    border-radius: 5px;
    color: $color-titleBody;
    display: flex;
    padding: 12px 5px;
    margin: 10px 0;
    flex-direction: column;

    .leftMiniBox3 {
      text-align: left;
      width: 70%;
      img {
        width: auto !important;
        margin-right: 5px;
      }
      .hoursImg {
        img {
          margin-right: 6.5px;
          width: 17px !important;
        }
      }
      .moneyImg {
        img {
          height: 16px;
          margin-right: 2px !important;
        }
      }
    }

    .rightMiniBox3 {
      text-align: right;
      width: 30%;
      margin-top: 4px;
    }

    &__item {
      display: grid;
      grid-template-columns: auto auto;
    }

    img {
      width: 18px;
      height: 19px;
      margin-bottom: 5px;
    }

    div {
      padding: 3px;
    }
  }
}
