@import '../../../../styles/variable';

.blockInfo {
  background-color: $bg-white;
  border: 1px solid $color-grey;
  border-radius: 5px;
  padding: 0 20px;
  margin-bottom: 20px;

  .title {
    padding: 24px 0px 15px 0px;
    border-bottom: 1px solid $color-grey;
    display: flex;

    img {
      margin: 0 5px;
    }

    div {
      color: $color-titleBody;
      font-size: 20px;
      font-weight: 500;
    }
  }

  .body {
    display: flex;
    padding: 20px 0 20px 0;

    .companyInfo {
      margin-right: 100px;

      .companyBody {
        display: flex;
        align-items: flex-start;

        .logo {
          margin: 0 10px;
          transform: translateY(30px);
        }

        .companyName {
          color: $color-titleBody;
          font-size: 20px;
          font-weight: 500;
          margin-bottom: 5px;
        }

        .description {
          display: flex;
          align-items: flex-start;

          img {
            margin-right: 5px;
          }

          .gmailIcon {
            margin-top: 5px;
          } 

          .content {
            color: $color-charcoal;
          }

          &:not(:last-child) {
            margin-bottom: 30px;
          }
        }
      }
    }

    .linkRule {
      margin-left: 35px;
      text-decoration: underline;
      color: $color-effort;
    }
  }
}