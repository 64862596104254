@import './../../styles/variable.scss';
.dateTime {
  :global {
    .input-group-text {
      border-top-right-radius: 0.375rem !important;
      border-bottom-right-radius: 0.375rem !important;
    }

    // .input-group-prepend {
    //   &:hover {
    //     cursor: pointer;
    //   }
    // }
  }
  img {
    width: 20px;
  }
}

.noLabel {
  legend {
    padding: 0;
    float: left;
    min-width: 0;
  }
  .MuiOutlinedInput-input {
    padding: 6px 0px 10px 15px !important;
  }
}

.openCalenderTime {
  :global {
    .MuiOutlinedInput-root {
      .MuiOutlinedInput-notchedOutline {
        border-color: $color-green !important;
      }
      .MuiSvgIcon-root {
        color: $color-green !important;
      }
    }
    .MuiInputLabel-root {
      color: $color-green !important;
    }
    .MuiInputLabel-root.Mui-error {
      color: $color-red !important;
    }
    .Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border-color: $color-red !important;
      }
      .MuiSvgIcon-root {
        color: $color-red !important;
      }
    }
  }
}
.selectTime{
  padding: 10px 15px !important;
  font-size: 14px;
  width: 100%;
  border-radius: 5px !important;
  height: 45px;
  :global{
    .ant-picker-input{
      position: relative;
    }
    .ant-picker-suffix{
      position: absolute;
      right: 0;
    }
  }
}
.selectTime:hover{
  border-color: #66d997 !important;
}
.selectTimeError{
  padding: 10px 15px !important;
  font-size: 14px;
  width: 100%;
  border-radius: 5px !important;
  border-color: red !important;
  height: 44px;
  :global{
    .ant-picker-input{
      position: relative;
    }
    .ant-picker-suffix{
      position: absolute;
      right: 0;
    }
  }
}
.inputTime{
  position: absolute !important;
}

.messageError {
  font-size: 14px;
  text-align: left !important;
  margin-left: 10px;
  margin-top: 5px;
}

.iconClose {
  width: 26px;
  height: 25px;
  box-sizing: border-box;
  border-radius: 50%;
  font-size: 19px;
  padding-left: 5px;
  color: #fea628;
  position: absolute;
  top: 11px;
  right: 65px;
  background-color: #efeded;

  svg {
    margin-top: -6px;
    margin-left: -1px;
  }

  &:hover {
    transform: scale(1.2);
    cursor: pointer;
  }
}

:global {
  .ant-picker-dropdown{
    z-index : 10000 !important;
  }
  .ant-picker-focused{
    border-color: #66d997 !important;
    box-shadow: none !important;
    border-width: 1px !important;
  }
  .ant-picker-footer{
    display: none !important;
  }
}