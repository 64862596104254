@import "./../../../../../styles/variable.scss";
.onLeave {
  :global {
    .modal-body {
      padding: 0 !important;
    }
  }
  .modalCreateContract {
    :global {
      .MuiOutlinedInput-root {
        svg {
          font-size: 19px !important;
        }
      }
    }
    padding: 20px 20px 35px 20px;
    :global {
      .MuiGrid-item {
        margin-top: 4px;
      }
    }
    .footerModal {
      width: 100%;
      padding-left: 15px;
      margin-bottom: -15px;
      :global {
        .MuiGrid-item {
          margin-top: 0px;
          padding-top: 15px;
        }
      }
    }
    .colorCheckBox {
      :global {
        .MuiTypography-root {
          font-size: 14px;
          color: $color-charcoal;
        }
      }
    }
    .onboard {
      margin-top: -20px;
    }
  }
  .remainingHours {
    background: #defdeb;
    padding: 12px 20px;
    color: #2ecc71;
  }
  .title {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #223354;
    margin: 0;
    padding: 0;
  }
  .checkbox {
    padding-top: 0 !important;
    margin-top: 0 !important;
    span {
      font-size: 14px;
    }
    label {
      margin-bottom: 0;
    }
  }
}

.infoUserRequestInput {
  p {
    padding: 0;
    margin: 0;
  }
}
.imgInfoUserRequestInput{
  object-fit: cover;
  border-radius: 50%;
}
.error{
  color: $color-red;
  font-size: 12px;
  margin: 5px 0 0 10px;
}
.inputText {
  label {
    font-size: 14px;
  }
  :global {
    .MuiFormLabel-root {
      top: 1px;
    }
  }
}
:global {
  .MuiAutocomplete-listbox {
    max-height: 20vh !important;
  }
  .MuiAutocomplete-option {
    padding: 12px !important;
    img {
      border-radius: 50%;
      object-fit: cover;
    }
    p {
      margin: 0;
    }
  }
}