@import 'styles//variable.scss';

.list-request-assets {
  table {
    .table-last-th {
      width: 60%;
    }
    td {
      cursor: pointer;
      .link-work-fl {
        color: $color-black;
        text-decoration: none;
      }
    }
  }
  .marginElementBottom {
    margin-bottom: 30px;
  }
  .text {
    color: $color-titleBody;
    font-weight: 500px;
    font-size: 16px;
  }
  .radioCustom {
    margin-bottom: 0;
    :global {
      .MuiTypography-root {
        font-size: 14px;
      }
    }
  }
  .division {
    cursor: pointer;
    position: relative;
    width: 100%;

    .total {
      position: absolute;
      right: 0px;
      width: auto;
      padding: 5px 8px;
      background: $color-headTable;
      color: $color-titleBody;
      border-radius: 5px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
    }

    .dot-status-accept {
      width: 13px;
      height: 13px;
      border-radius: 13px;
      margin-top: 15px;
    }
  }
  .bgRadioProcessing,
  .bgRadioHandled {
    width: 13px;
    height: 13px;
    display: inline-block;
    border-radius: 50%;
  }
  .bgRadioProcessing {
    background-color: $color-blue1;
  }
  .bgRadioHandled {
    background-color: $color-active;
  }
}
