@import './../../../../styles/variable.scss';

.deparment-detail {
  padding-bottom: 35px;

  .members-projects {
    .textInfoProject {
      color: #223354;
      font-weight: bold;
      margin: 20px 10px;
    }
    .noDataProjects {
      position: absolute;
      left: 42%;
      color: #223354;
      font-weight: bold;
      text-align: center;
    }

    .headerTable {
      background: #e9ebee;
      color: #223354;
    }

    .evenRowTable {
      background: #f9f9f9;
    }
  }

  .breadcrumb {
    padding-left: 0;
    background: transparent;
  }

  .export-project {
    position: absolute;
    top: 70px;
    right: 30px;
    .ctButton {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: $color-white;
      background-color: $bg-yellow1;
      border-radius: 5px;
      padding: 7px 35px 7px 32px;
      border: 1px solid $bg-yellow1;
      margin-left: 2px;
      &:active {
        background-color: $color-buttonSubmitActive !important;
        color: $bg-body !important;
      }

      &:hover {
        background-color: $bg-white;
        color: $bg-yellow1;
        border: 1px solid $bg-yellow1;
      }

      img {
        width: 14px;
        height: 14px;
        margin-right: 10px;
      }
      :global {
        svg {
          font-size: 25px;
        }
      }

      &.iconButtonExport {
        svg {
          margin-right: 5px;
        }
      }
    }
  }

  .previous-name {
    padding-left: 0;
    padding-right: 0;
    color: $color-truncate;
    font-weight: 400;
    margin-top: 7px;
  }

  .active-name {
    font-size: 14px;
    color: $color-navy;
    font-weight: 500;
  }

  .border-top {
    border-top: 1px solid $color-wheat;
  }

  .no-padding-right {
    padding-right: 0;
  }

  .no-padding-left {
    padding-left: 0;
  }

  .head-title {
    margin-top: 10px;
    color: $color-navy;
    font-weight: 500;
  }

  .update-icon {
    border: 1px solid $color-grey;
    cursor: pointer;
    width: 25px;
    height: 25px;
    background: $bg-body;
    border-radius: 50%;
    color: $bg-yellow1;
    text-align: center;
    font-size: 13pt;
    position: absolute;

    svg {
      margin-bottom: 3px;
    }
  }

  .position-icon-infor {
    top: 10%;
    right: 3%;
  }

  .position-icon-list {
    top: 10%;
    right: 0%;
  }

  .information {
    padding-bottom: 25px;
    background: $color-table;
    box-shadow: 0px 5px 25px $color-black2;
    border-radius: 5px;

    .bg-img {
      width: 100%;
      height: 111px;
      background-size: cover;
      background-image: url('./../../../../assets/img/icons/DepartmentDetail.png');
      box-shadow: 0 5px 5px rgba(182, 182, 182, 0.75);
    }

    .header {
      position: relative;
      width: 100%;
      margin-bottom: 20%;

      .avatar {
        bottom: -35%;
        left: 4%;
        width: 95px;
        height: 95px;
        position: absolute;
        border-radius: 50% 50%;

        img {
          width: 100%;
          height: 100%;
        }

        .edit-zone {
          width: 100%;
          height: 100%;
          cursor: pointer;
          position: absolute;
          top: 0px;
          z-index: 10;
          .edit-button {
            position: absolute;
            font-size: 20pt;
            top: 50%;
            left: 38%;
            z-index: 10;
            color: white;
          }

          .bg-button {
            position: absolute;
            opacity: 50%;
            top: 50%;
            background: $color-nav;
            width: 100%;
            height: 100%;
          }
        }
      }

      .department-name {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 22px;
        color: $color-table;
        position: absolute;
        left: 40%;
        bottom: 0%;
      }
    }

    .description {
      ul {
        padding-left: 5%;
        li {
          list-style-type: none;
          .nav-link {
            padding: 0;
            margin-bottom: 5px;
            text-decoration: underline;
            color: $color-managerNameBox;
          }
        }
      }
    }
  }

  .list-members {
    background: $color-table;
    box-shadow: 0px 5px 25px $color-black2;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 1.5%;
    padding: 0.5% 1.5%;
    .header {
      position: relative;

      .list-title {
        padding-bottom: 0.8%;
      }
    }

    .body::-webkit-scrollbar {
      width: 7px;
    }

    .body.thin::-webkit-scrollbar {
      width: 2px;
    }

    .body::-webkit-scrollbar-track {
      border-radius: 10px;
      background: $color-white;
    }

    .body::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: $bg-yellow1;
    }

    .body {
      // overflow-y: scroll;
      // height: 280px;
      .member {
        cursor: pointer;
        margin-top: 25px;
        margin-bottom: 25px;
        width: 30%;
        display: inline-block;
        .member-body {
          display: flex;

          .avatar-member {
            margin-right: 0;
            width: 90px;
            height: 90px;
            object-fit: cover;
            background-size: cover;
            border-radius: 50% 50%;
          }

          .description {
            position: relative;
            margin-left: 15px;
            .name {
              font-weight: 500;
              font-size: 0.9rem;
              color: $color-navy;
              margin-bottom: 10px;
            }
            .description-body {
              position: absolute;
              bottom: 0;
              .email {
                font-size: 0.8rem;
                color: $color-truncate;
              }

              .code {
                width: max-content;
                font-size: 0.8rem;
                color: $color-truncate;
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    .information {
      .header {
        .avatar {
          width: 97px;
          height: 97px;
          bottom: -35%;
        }

        .department-name {
          left: 55%;
        }
      }
    }

    .list-members {
      background: $color-table;
      box-shadow: 0px 5px 25px $color-black2;
      border-radius: 5px;
      width: 100%;
      margin: 1.5% 0;
      padding: 0.5% 1.5%;

      .header {
        position: relative;

        .list-title {
          padding-bottom: 0.8%;
        }
      }

      .body::-webkit-scrollbar {
        width: 5px;
      }

      .body.thin::-webkit-scrollbar {
        width: 2px;
      }

      .body::-webkit-scrollbar-track {
        border-radius: 10px;
        background: $color-white;
      }

      .body::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: $bg-yellow1;
      }

      .body {
        // overflow-y: scroll;
        // height: 280px;
        .member {
          // cursor: pointer;
          // margin-top: 25px;
          // margin-bottom: 25px;
          // width: 30%;
          // display: inline-block;
          .member-body {
            .avatar-member {
              width: 70px;
              height: 70px;
            }

            .description {
              .name {
                font-size: 0.9rem;
              }
              .description-body {
                .email {
                  font-size: 0.9rem;
                }

                .code {
                  font-size: 0.9rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
