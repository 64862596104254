@import 'styles/variable.scss';
@import "../../../../../src/styles/mixin";

@mixin statistical {
  .statistical {
    .statistical-item {
      border-radius: 5px;
      padding: 13px 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $bg-plum;
      margin-bottom: 5px;

      .statistical-item-title {
        line-height: 0;
        margin-left: 8px;
        color: $color-navy;
      }
    }

    .hourglass-padding {
      img {
        margin-left: 4px;
      }
      .statistical-item-title {
        margin-left: 10px;
      }
    }

    .deleted-hourglass-padding {
      .statistical-item-title {
        margin-left: 5px;
      }
    }

    .special-item {
      background-color: $color-totalEmployee;
    }
  }
}

.request-timekeeping-individual {
  @include statistical;
  .filter-date {
    @media (min-width: 1024px) and (max-width: 1472px) {
      flex-direction: column;
      .between {
        display: none;
      }
      .from-date {
        margin-bottom: 10px;
      }
    }
  }
  .total {
    background-color: $color-white !important;
  }
  .from-to {
    display: flex;
  }
  .error-input {
    display: none;
  }
  .between {
    margin: 5px 10px 0px 10px;
  }
  .border-bottom-tooltip-none {
    .MuiTooltip-tooltip {
      border: none !important;
    }
  }
  .status-in-progress {
    color: $color-blue1;
  }
  .status-completed {
    color: $color-active;
  }
  .bolder-500 {
    font-weight: 500;
  }
  .added-info {
    color: $color-silver;
    white-space: nowrap;
    p {
      margin: 0;
    }
    @media screen and (max-width: 1149px) {
      flex-direction: column;
    }
  }
  table {
    td {
      word-break: break-word;
      cursor: pointer;
      .link-work-fl {
        color: $color-black;
        text-decoration: none;
      }
    }
  }
  .short {
    width: 100px;
  }
  .title {
    font-weight: 500;
    line-height: 24px;
  }
  .number-request-day {
    margin-right: 7.65px;
    position: relative;

    .number {
      padding: 0px 4px;
      border: 1px solid transparent;
      border-radius: 50%;
      font-size: 10px;
      background: $color-red;
      color: $color-table;
      position: absolute;
      left: -7px;
      top: -7px;
    }
  }
  .info {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $color-wheat;
    padding: 15px 0;
    margin-bottom: 12px;

    img {
      margin-right: 20px;
      width: 70px;
      height: 70px;
      object-fit: cover;
      border-radius: 50%;
    }

    .name {
      color: $color-navy;
      font-size: 20px;
      font-weight: 500;
    }

    .position {
      color: $color-nav;
    }
  }
  .dayOff {
    margin: 19px 0 0 23px;
    display: flex;
    align-items: flex-start;
    padding: 16.75px 17.5px;
    background-color: $bg-blue1;
    border-radius: 5px;

    img {
      margin-right: 12.5px;
    }

    .label {
      color: $color-blue4;
    }

    .value {
      font-size: 20px;
      font-weight: 500;
      color: $color-blue4;
    }
  }
  .statistical {
    padding: 15px 0 10px 0;
    border-top: 1px solid $color-wheat;
    border-bottom: 1px solid $color-wheat;
  }

  .filter-by-date {
    margin-bottom: 28px;
  }

  .filter-by-input {
    padding-top: 15px;

    .padding-element-filter {
      padding-bottom: 15px !important;
    }
  }
}

.minWidth1200{
  @include min-width-1200;
}
