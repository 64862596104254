@import "../../../../styles/variable.scss";
@import "../../../../../src/styles/mixin";
.calendar {
  .calendarHeader {
    border: 1px solid $color-wheat;
    border-radius: 4px;
    overflow: hidden;

    .top {
      display: flex;
      align-items: center;
      background-color: $bg-salmon;
      padding: 6px 17.5px;
      border-radius: 4px 4px 0 0;

      .month {
        color: $color-navy;
        font-weight: 500;
      }

      .preBtn,
      .nextBtn {
        background-color: $bg-white;
        width: 30px;
        height: 30px;
        min-width: 30px;
        margin: 0 2px;
      }

      @mixin icon {
        padding: 6px;
        border-radius: 50%;
      }

      .fulltimeIcon {
        @include icon();
        background-color: $color-active;
      }

      .lateIcon {
        @include icon();
        background-color: $color-yellow2;
      }

      .loseIcon {
        @include icon();
        background-color: $color-switch-base;
      }

      .offIcon {
        @include icon();
        background-color: $color-red;
      }
    }

    .bottom {
      display: flex;
      background-color: $bg-white;

      .weekDay {
        padding: 4px;
        text-align: center;
        flex-basis: calc(100% / 7);
        border: 1px solid $color-wheat;
        font-weight: 500;
      }
    }
  }
  .dots {
    &:hover {
      font-size: 18px;
      transition: font-size 0.1s;
      transition-timing-function: ease;
    }
  }
  .calendarBody {
    display: flex;
    flex-wrap: wrap;
    border-radius: 4px;
    margin-top: 6px;
    cursor: pointer;
    .date {
      min-height: 104px;
      flex-basis: calc(100% / 7);
      border: 1px solid $color-wheat;
      background-color: $bg-white;
      position: relative;
    }

    .normalDate {
      padding: 5px;
    }

    .disable {
      pointer-events: none;
      background-color: $bg-body;
      height: 100%;
    }

    @mixin border {
      width: 3px;
      height: 100%;
      margin-right: 5px;
    }

    .fulltime {
      background-color: $color-green1;
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      padding-right: 5px;

      .borderLeft {
        @include border();
        background-color: $color-active;
      }

      .content {
        flex-grow: 1;
      }
    }

    .lateTime {
      background-color: $bg-yellow3;
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      padding-right: 5px;

      .borderLeft {
        @include border();
        background-color: $color-yellow2;
      }

      .content {
        flex-grow: 1;
      }
    }

    .loseTime {
      background-color: $bg-magnolia;
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      padding-right: 5px;

      .borderLeft {
        @include border();
        background-color: $color-switch-base;
      }

      .content {
        flex-grow: 1;
      }
    }

    .offTime {
      background-color: $bg-pink1;
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      padding-right: 5px;

      .borderLeft {
        @include border();
        background-color: $color-red;
      }

      .content {
        flex-grow: 1;
      }
    }

    .full {
      color: $color-active1;
    }

    .late {
      color: $color-late;
    }

    .off {
      color: $color-red;
    }
  }
}
.filterMonth {
  display: flex;
  justify-content: center;
  .month {
    color: #223354;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }
}
.suggestRequest {
  width: 300px;
  max-height: 450px;
  background: #ffffff;
  box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);
  border-radius: 4px;
}
.suggestRequest::-webkit-scrollbar {
  width: 2px;
}

.suggestRequest.thin::-webkit-scrollbar {
  width: 2px;
}

.suggestRequest::-webkit-scrollbar-track {
  border-radius: 10px;
  background: $color-white;
}

.suggestRequest::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: $bg-yellow1;
}

.titleSuggestRequest {
  color: #223354 !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  margin: 0 0 0 0;
  padding: 6px 15px;
}
.requestItem {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 40px !important;
  color: #333333 !important;
  transition-timing-function: ease !important;
  padding: 3px 16px !important;
  &:hover {
    color: #223354 !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    transition: font-weight 0.3s, font-size 0.3s, color 0.3s !important;
  }
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    height: 0;
    width: calc(100% - 32px);
    border-bottom: 1px solid #E7E7E7;
  }
}
.standardShift {
  background: linear-gradient(91.34deg, $color-blue2 0%, #9c86f3 99.96%);
  box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);
  border-radius: 5px;
  height: 74px;
  margin-bottom: 10px;
  padding: 10px 0 0 20px;
  position: relative;
  @media (min-width: 1024px) and (max-width: 1200px) {
    padding: 10px 0 0 8px;
  }
  .backGround {
    position: absolute;
    right: 22px;
    right: 0;
    top: 0px;
  }
  .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $bg-tooltip;
    margin: 0 0 8px 0;
  }
  .time {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: $bg-tooltip;
    @media (min-width: 1024px) and (max-width: 1170px) {
      margin-top: 20px;
    }
    .afterNoon {
      margin-left: 30%;
      @media (min-width: 1250px) and (max-width: 1600px) {
        margin-left: 10%;
      }
      @media (min-width: 1024px) and (max-width: 1250px) {
        margin-left: 5%;
      }
    }
    img {
      margin: -2px 6px 0 0;
    }
  }
}

.dayOff {
  display: flex;
  align-items: flex-start;
  padding: 16.75px 17.5px;
  border-radius: 5px;
  margin-bottom: 10px;
  background: #ebf9ff;
  border: 1px solid $color-border-dayy-off;
  box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);
  border-radius: 5px;
  img {
    margin-right: 12.5px;
  }

  .label {
    color: $color-label-time-keeping;
  }

  .value {
    font-size: 20px;
    font-weight: 500;
    color: $color-label-time-keeping;
  }
}

.timeSheet {
  background-color: $bg-white;
  border: 1px solid $color-wheat;
  border-radius: 5px;
  padding: 0 15px;
  box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);

  .info {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $color-wheat;
    padding: 15px 0;

    img {
      margin-right: 20px;
      width: 70px;
      height: 70px;
      object-fit: cover;
      border-radius: 50%;
    }

    .name {
      color: $color-navy;
      font-size: 20px;
      font-weight: 500;
    }

    .position {
      color: $color-nav;
    }
  }
}

.allTime {
  padding: 15px 0 10px 0;
}

.timeSheetItem {
  display: flex;
  justify-content: space-between;
  padding: 9px 17.5px;
  background-color: $bg-plum;
  margin-bottom: 5px;
  border-radius: 5px;
  height: 39px;
  .item {
    display: flex;

    img {
      margin-right: 5px;
    }
  }
  .label {
    color: $color-navy;
  }
  .value {
    color: $color-navy;
    font-weight: 500;
  }
}

.special {
  background-color: $color-yellow4;
}

.totalTimeSheet {
  width: 60%;
  .info {
    padding: 20px;
    background-color: $bg-salmon;

    .name {
      font-size: 20px;
      font-weight: 500;
    }

    .email {
      color: $color-nav;
    }
  }

  .totalTimeSheetBody {
    .field {
      padding: 12px 20px;

      .label {
        color: $color-navy;
        font-weight: 500;
      }

      .value {
        color: $color-charcoal;
      }
    }
    .bdBottom {
      display: flex;
      .left {
        width: 50%;
      }
      .right {
        width: 50%;
      }
    }
    .suggest {
      cursor: pointer;
      transition: all 0.2s ease-out;
      &:hover {
        background: rgba(254, 166, 40, 0.4);
        color: $color-navy;
        font-weight: 500;
      }
    }
    .hrFull {
      padding: 0;
      width: 93%;
      margin: 0 auto;
    }
    .hrShortRight {
      margin: 0;
      width: 95%;
    }
    .hrShortLeft {
      margin: 0;
    }
  }
}
.fullWidth {
  width: 100%;
  .hrFull {
    width: 96% !important;
  }
}
.otherInfo {
  background: #f9f9f9;
  width: 40%;
  padding: 5px 15px;
  .title {
    color: $color-navy;
    margin: 12px 0;
    font-size: 16px;
    font-weight: 500;
  }

  .otherInfoItem {
    margin-bottom: 12px;

    .itemName {
      font-weight: 500;
      color: $color-charcoal;
      margin-right: 3px;
    }

    .total {
      color: $color-charcoal;
      margin-right: 15px;
      font-weight: 500;
    }

    .label {
      color: $color-navy;
      font-weight: 500;
    }

    .value {
      color: $color-nav;
    }
  }

  .waitStatus {
    font-weight: 500;
    color: $color-blue1;
  }

  .approvedStatus {
    font-weight: 500;
    color: $color-active;
  }

  .rejectedStatus {
    font-weight: 500;
    color: $color-deny;
  }

  .openStatus {
    font-weight: 500;
    color: $color-status-open;
  }
  
  .reworkStatus {
    font-weight: 500;
    color: $color-rework;
  }
  
  .recallStatus {
    font-weight: 500;
    color: $color-status-removed;
  }
}
.layout {
  display: flex;
  width: 100%;
  justify-content: center;
}
.modalInfoTimekeeping {
  :global {
    .MuiPaper-root {
      min-width: 878px;
      .modal-body {
        padding: 0 !important;
      }
    }
  }
}
.popover {
  background-color: $bg-plum;
  padding: 16px;

  .title {
    font-size: 16px;
    color: $color-navy;
  }

  .request {
    padding: 5px 0;

    &:not(:last-child) {
      border-bottom: 1px solid $color-wheat;
    }

    .value {
      color: $color-navy;
      font-weight: 500;
    }

    .status {
      font-weight: 500;
      color: $color-blue1;
    }
  }
}

.labelOther {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #333333;
}

.blockRequest {
  margin-left: 5px;
  .infoBasic {
    margin-left: 28px;
  }
}
.minWidth1200 {
  @include min-width-1200;
}

.boxHeader {
  display: flex;
}

.holiday {
  margin-top: -3px;
  margin-left: 5px;
}

.headBlockTimeKeeping {
  display: flex;
  @media (min-width:1024px) and (max-width:1488px) {
    width: 75px;
  }
  flex-wrap: wrap;
  flex-direction: row-reverse;
  right: 6px;
  top: 6px;
  position: absolute;
  img {
    margin-right: 4px;
  }
}
