.collapse {
  color: #fea628 !important;
  margin-right: -10px !important;
  border: none !important;
  -webkit-text-stroke: 2px;

  &:not(:disabled):hover {
    color: #fea628;
  }
}

.filterContainer {
  border-radius: 10px !important;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 5px 25px 0px rgba(34, 51, 84, 0.15);
  font-size: 14px !important;
  font-family: Roboto;

  :global(.ant-card-head) {
    border: none !important;
  }

  .searchContainer {
    display: flex;
    gap: 0.625rem;
    color: #223354;
    cursor: pointer;

    .searchIcon {
      display: inline;
    }

    .searchTitle {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
    }
  }

  .filterRow {
    margin-bottom: 1rem;

    &.filterSearch {
      :global(.ant-form-item-label) {
        left: 28px;
      }
    }

    &.filterGrid {
      display: grid;
      grid-template-columns:
        calc(100% / 4 - 3rem / 4) calc(100% / 4 - 3rem / 4) calc(100% / 4 - 3rem / 4) calc(100% / 4 - 3rem / 4)
        calc(100% / 4 - 3rem / 4) calc(100% / 4 - 3rem / 4);
      column-gap: 1rem;

      & :global(.ant-picker) {
        width: 100%;
      }
    }

    &.filterGridDate {
      display: grid;
      grid-template-columns:
        calc(100% / 4 - 3rem / 4) calc(100% / 4 - 3rem / 4) calc(100% / 4 - 3rem / 4) calc(100% / 4 - 3rem / 4)
        calc(100% / 4 - 3rem / 4) calc(100% / 4 - 3rem / 4);
      column-gap: 1rem;

      & :global(.ant-picker) {
        // max-width: 268px;
      }
    }

    &.filterRowButton {
      display: flex;
      justify-content: flex-end;
      column-gap: 1rem;
      margin-bottom: 0;
    }

    &:hover {
      border-color: #fea628;
    }

    .projectName {
      :global(.ant-select-selection-item-remove) {
        color: #e7e7e7;
      }

      :global(.anticon-close) {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 14px;
        width: 14px;

        svg {
          padding: 2px;
        }
      }

      :global(.ant-select-selector) {
        padding: 0 4px;

        &:focus {
          box-shadow: none;
        }
      }

      :global(.ant-select-selection-overflow) {
        top: -1px !important;
      }
      :global(.ant-select-selection-overflow-item) {
        top: -6px !important;
      }

      :global(.ant-select-selection-overflow) {
        margin-right: 25px;
      }
    }

    :global {
      .ant-btn.ant-btn-lg {
        font-size: 14px;
        font-weight: 500;
      }

      .ant-input-affix-wrapper {
        height: 41px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;

        // &:hover {
        //   border-color: #fea628;
        // }
        // &:focus {
        //   border-color: #fea628;
        // }
      }

      .ant-input-affix-wrapper-focused,
      .ant-select-selector {
        box-shadow: none !important;
      }

      .ant-input-clear-icon {
        display: flex;
        align-items: center;
      }

      .ant-select-selector {
        height: 41px !important;
        border-radius: 4px !important;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .ant-select-selection-search-input {
        height: 41px !important;
      }

      .ant-input-prefix {
        font-size: 21px;
        color: #8d8d8d;
      }

      .ant-input-suffix {
        font-size: 21px;
      }

      .ant-picker {
        height: 41px !important;
        border-radius: 4px !important;
      }

      .ant-btn {
        width: 150px;
        height: 41px;
        border-radius: 5px;
        font-weight: 500 !important;
      }

      .ant-select-arrow {
        font-size: 20px;
        color: #8d8d8d;
      }
    }

    .btnSubmit {
      border: none;
      outline: none;
      background-color: #fea628;
      color: #fff;

      &:hover {
        background-color: #ffbd52;
      }
    }

    .btnReset {
      border-color: #fea628;
      outline: none;
      color: #fea628;

      &:hover {
        background-color: #fff7e9 !important;
      }
    }

    .btnExport {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;

      @extend .btnSubmit;
      text-shadow: none;
      box-shadow: none;
      
      svg {
        color: #fff;
      }
    }
  }
}

:global(.ant-tag-has-color) {
  padding: 3px 8px !important;
  color: #fff !important;
  width: 90px;
  font-size: 14px !important;
  text-align: center;
  margin-right: 0 !important;
}

:global(.ant-select-item-option-selected) {
  // background-color: #E9EBEE !important;
}

:global(.ant-select-dropdown) {
  padding-bottom: 0 !important;
}

:global(.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state) {
  display: none;
}

:global(.ant-select-item-option-selected:not(.ant-select-item-option-disabled)) {
  font-weight: 400 !important;
}

:global(.ant-select-focused .ant-select-selector .ant-select-selection-item-remove > *) {
  background-color: #66d997 !important;
  color: white;
}
