@import 'styles/variable.scss';
@import "../../../../../src/styles/mixin";
@mixin statistical {
  .statistical {
    .statistical-item {
      border-radius: 5px;
      padding: 13px 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $bg-plum;
      margin-bottom: 5px;

      .statistical-item-title {
        line-height: 0;
        margin-left: 8px;
        color: $color-navy;
      }
    }

    .hourglass-padding {
      img {
        margin-left: 4px;
      }
      .statistical-item-title {
        margin-left: 10px;
      }
    }

    .deleted-hourglass-padding {
      .statistical-item-title {
        margin-left: 5px;
      }
    }

    .special-item {
      background-color: $color-totalEmployee;
    }
  }
}

.ot-timekeeping-individual {
  @include statistical;
  .total{
    background-color: $color-white !important;
  }
  .from-to{
    display: flex;
  }
  .error-input{
    display: none;
  }
  .between{
    margin: 5px 10px 0px 10px;
  }
  .info {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $color-wheat;
    padding: 15px 0;
    margin-bottom: 12px;

    img {
      margin-right: 20px;
    }

    .name {
      color: $color-navy;
      font-size: 20px;
      font-weight: 500;
    }

    .position {
      color: $color-nav;
    }
  }
  .dayOff {
    margin: 19px 0 0 23px;
    display: flex;
    align-items: flex-start;
    padding: 16.75px 17.5px;
    background-color: $bg-blue1;
    border-radius: 5px;

    img {
      margin-right: 12.5px;
    }

    .label {
      color: $color-blue4;
    }

    .value {
      font-size: 20px;
      font-weight: 500;
      color: $color-blue4;
    }
  }
  .statistical {
    padding: 15px 0 10px 0;
    border-top: 1px solid $color-wheat;
    border-bottom: 1px solid $color-wheat;
  }

  .filter-by-date {
    margin-bottom: 28px;
  }

  .filter-by-input {
    padding-top: 15px;

    .padding-element-filter {
      padding-bottom: 15px !important;
    }
  }
}

.minWidth1200{
  @include min-width-1200;
}
