.modal {
  width: 880px !important;
  font-family: 'Roboto', sans-serif;

  :global(.ant-modal-content) {
    padding: 0;
    border-radius: 5px !important;
    overflow: hidden;

    :global(.ant-modal-close) {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #223354;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      top: 10px;
      right: 15px;
      align-items: center;

      :global(.ant-modal-close-x) {
        font-size: 18px;
        color: #fea628;
        line-height: 17px;
        text-align: center;
        width: unset;
        height: unset;
      }
    }

    :global(.ant-modal-header) {
      padding: 13px 20px;
      background-color: #222433;

      :global(.ant-modal-title) {
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-weight: 500;
        color: white;
      }
    }

    :global(.ant-modal-body) {
      padding: 20px;
      max-height: calc(100vh - 270px);
      overflow: auto;
      padding-bottom: 55px;
    }

    :global(.ant-modal-footer) {
      padding: 20px;
      border: none;
      margin-top: 0;
      display: flex;
      gap: 10px;

      & > button {
        flex: 1;
        min-height: 36px;
      }
    }
  }
}

.dialog {
  font-family: 'Roboto', sans-serif;
  :global(.ant-modal-content) {
    padding: 20px !important;
  }

  :global(.ant-modal-confirm-content) {
    width: -webkit-fill-available;
  }
  :global(.ant-modal-content) {
    border-radius: 4px !important;
  }
  :global(.ant-modal-body) {
    padding: 0 !important;
  }

  .btn {
    font-size: 14px;
    font-weight: 500;
    color: #8d8d8d;
    min-width: 0;
    border: none;

    &:hover {
      color: #8d8d8d;
    }

    &__ok {
      color: #fea628;

      &:hover {
        color: #fea628;
      }
    }
  }
}

.formReport {
  display: flex;
  flex-direction: column;
  gap: 13px;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  :global {
    .ant-btn .ant-btn-lg {
      font-size: 14px;
      font-weight: 500;
    }

    .ant-input-affix-wrapper,
    .ant-input-number-input-wrap {
      height: 50px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px !important;
    }

    .ant-input-affix-wrapper-focused,
    .ant-select-selector,
    .ant-input-textarea,
    .ant-input:focus {
      box-shadow: none !important;
    }

    .ant-input-clear-icon {
      display: flex;
      align-items: center;
    }

    .ant-select-selector {
      height: 50px !important;
      border-radius: 4px !important;
      justify-content: center;
      align-items: center;
      padding: 15px;
    }

    .ant-select-arrow,
    .ant-picker-suffix {
      font-size: 20px;
      color: #8d8d8d;
    }

    .ant-select-selection-search,
    .ant-select-selection-search-input {
      height: 50px !important;
    }

    .ant-select-selection-search > input {
      margin-top: -2px !important;
    }

    .ant-select-selection-item {
      font-weight: 500;
    }

    .ant-input-prefix {
      font-size: 21px;
    }

    .ant-input-suffix {
      font-size: 21px;
    }

    .ant-picker {
      height: 50px !important;
      padding: 15px;
      border-radius: 4px !important;
      width: 100%;
    }

    textarea.ant-input {
      border-radius: 4px !important;
      padding: 14px 15px;
      min-height: 50px !important;
      line-height: 21px;
    }
    .ant-input-textarea-show-count:after {
      position: absolute;
      bottom: 25px;
      right: 15px;
      font-size: 12px;
      z-index: 1;
    }

    .ant-form-item .ant-form-item-label label {
      top: 25px !important;
      left: 10px !important;
    }

    .ant-input-number {
      width: 100%;
    }
  }

  .groupReport {
    display: flex;
    flex-direction: column;
    padding: 8px 16px 4px 16px;
    margin-bottom: 12px;
    border-radius: 5px;
    border: 1px solid #e7e7e7;
    background-color: #f9f9f9;

    .reports {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .btnDelete {
      border: none;
      border-radius: 50%;
      padding: 0;
      color: #e74c3c;
      margin-top: -8px;
      width: fit-content;
      align-self: center;
      margin-top: -20px;

      span {
        text-decoration: underline;
      }
    }

    .reportItem {
      margin-top: 12px;
      display: flex;
      gap: 4px;
      flex-direction: column;
      padding: 0px 16px 8px 16px;
      border-radius: 5px;
      border: 1px solid #e7e7e7;
      background-color: #fff8ee;
      position: relative;

      .btnDeleteItem {
        @extend .btnDelete;
        background-color: #e74c3c;
        margin: 0;
        position: absolute;
        top: 0px;
        right: 0px;
        transform: translate(50%, -50%);
      }

      :global(.ant-picker-suffix) {
        font-size: 20px;
        color: #8d8d8d;
      }
    }

    .formRow {
      display: flex;
      gap: 8px;

      :global(.ant-form-item) {
        flex: 1;
      }
    }

    .totalHours {
      color: #333;
      font-weight: 500;
    }
  }

  .addReportButton {
    width: fit-content !important;
    border: none;
    background-color: transparent;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    color: #223354;

    &:hover {
      background-color: transparent;
      box-shadow: none;
      color: #fea628;
    }
    &:focus {
      color: #223354;
      border: none;
      outline: none;
    }

    span {
      color: #333;
    }
  }

  .formTime {
    display: flex;
    gap: 20px;

    .formWorkDate {
      flex: 1;
    }

    .formWorkTime {
      flex: 1;
    }
  }
}

.selectProcessType {
  :global(.ant-select-selection-item) {
    font-weight: 400;
  }
}

.modalCreate {
  :global(.ant-modal-content) {
    margin-top: 20px;
  }

  :global(.ant-modal-body) {
    padding-bottom: 103px !important;
    max-height: calc(100vh - 160px) !important;
  }

  :global(.ant-picker-dropdown) {
    top: 54.5px !important;
  }

  :global {
    .ant-input-affix-wrapper,
    .ant-input-number-input-wrap {
      height: 45px;
    }

    .ant-select-selector {
      height: 45px !important;
    }

    .ant-select-selection-search,
    .ant-select-selection-search-input {
      height: 45px !important;
    }

    .ant-picker {
      height: 45px !important;
    }

    textarea.ant-input {
      border-radius: 4px !important;
      padding: 4px 15px;
      min-height: 45px !important;
      line-height: 21px;
    }

    .ant-form-item .ant-form-item-label label {
      top: 23px !important;
    }
  }
}

.rejectReason {
  display: flex;
  padding: 16px 12px;
  border-radius: 5px;
  background-color: #ffe7c3;

  span {
    color: #353535;
    font-weight: 500;
    width: 20%;
  }

  p {
    width: 80%;
    color: #393939;
    margin: 0;
    text-align: justify;
  }
}
