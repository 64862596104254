@import './../../styles/variable.scss';
.navbar {
  display: flex;
  list-style: none;
  padding-left: 10px;
  margin-bottom: 30px;

  .link {
    color: $color-nav;
    font-size: 17px;
    margin-right: 10px;
    line-height: 22px;
    padding-bottom: 10px;
    text-transform: uppercase;
    text-decoration: none;
    .navItem {
      padding: 0 10px;
    }
  }
  .isActive {
    color: $color-navActive;
    .underline {
      border-bottom: 2px solid $color-menuActive;
      margin-top: 10px;
    }
  }
}
