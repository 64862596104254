@import 'styles/variable.scss';

.detail {
  a {
    color: $color-silver;
    font-size: 14px;
    text-decoration: underline;
  }
}

.avatarDashboard {
  background: $bg-body;
}

.iconNotifyTop {
  width: 70px;
  float: left;
}

.marqueeTop {
  display: flex;
  align-items: center;

  .itemMarquee {
    padding: 10px 20px;
    font-size: 14px;
    line-height: 22px;
    background-color: $bg-notify;
    color: $color-navActive;
    border-radius: 50px;
    margin: 0 10px;
  }
}

.infoEmployee {
  background: url('./../../../assets/img/icons/dashboard/bg_info.svg') no-repeat;
  background-size: cover;
  padding: 21px 15px;
  border-radius: 5px;
  height: 100%;

  @media (min-width: 1200px) and (max-width: 1500px) {
    padding: 10px 15px;
  }

  p {
    color: $color-wheat;
    margin-bottom: 0px;
  }

  .textTitleYellow {
    color: $color-yellow2;
    font-weight: 500;
  }

  .leftInfoEmployee {
    width: 70%;

    .avatarInfoEmployee {
      display: flex;
      align-items: center;

      .avatar {
        border-radius: 50%;
        height: 60px;
        width: 60px;
        @media (min-width: 1200px) and (max-width: 1500px) {
          height: 50px;
          width: 50px;
        }
        overflow: hidden;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .info {
        p {
          font-size: 12px;
          line-height: 20px;
        }
        h4 {
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 0px;
          color: $color-yellow2;
          @media (min-width: 1200px) and (max-width: 1500px) {
            font-size: 16px;
          }
        }
      }
    }

    .avatarComplete {
      margin-top: 15px;
      @media (min-width: 1200px) and (max-width: 1500px) {
        margin-top: 0;
      }
    }
    .avatarBar {
      display: flex;
      color: $color-green2;
      margin-top: -5px;
      width: 250px;
    }
  }

  .rightInfoEmployee {
    width: 30%;

    .manage {
      font-size: 14px;
      text-decoration: underline;
    }
    .onboard {
      margin-top: 52px;
      font-weight: 400;
      font-size: 14px;
      line-height: 32px;
      color: #e9ebee;
    }
    .detail {
      a {
        color: $bg-salmon;
        font-size: 14px;
        text-decoration: underline;
      }
    }
  }
}

.boxTimeKeeping {
  height: 156px;
  p {
    margin-bottom: 8px;
  }

  @mixin border {
    width: 3px;
    height: 100%;
    margin-right: 5px;
  }

  .boxTimekeepingDetail {
    padding: 5px 15px;
    border-radius: 5px;
    box-sizing: border-box;

    &.fulltime {
      background-color: $color-green1;
      border: 1px solid $color-active;
    }

    &.lateTime {
      background-color: $bg-yellow3;
      border: 1px solid $color-yellow2;
    }

    &.loseTime {
      background-color: $bg-magnolia;
      border: 1px solid $color-switch-base;
    }

    &.offTime {
      background-color: $bg-pink1;
      border: 1px solid $color-red;
    }
  }
}

.boxTimeKeepingStatistical {
  :global {
    .title-body {
      margin-bottom: 0px;
    }
  }
  .filterDate {
    max-width: 135px;
  }

  .itemTimeKeepingStatistical {
    border-radius: 5px;
    padding: 11px 18px;
    margin-bottom: 5px;

    &.minuteLate {
      background: #ffe8ec;
      color: #ee0000;
    }

    &.fines {
      background: #f1e8ff;
      color: #6200ee;
    }

    &.dayOff {
      background: #ebf9ff;
      color: #33c2ff;
    }

    &.dayOffDashboard {
      background: #ebf9ff;
      color: #33c2ff;
    }

    &.OT {
      background: #fff6e8;
      color: #ffa31a;
    }

    &.leaveDay {
      background: #f3f7fb;
      color: #526487;
    }

    .text {
      margin-left: 5px;
      margin-bottom: 0px;

      font-size: 14px;
      line-height: 22px;
    }

    svg {
      font-size: 20px;
    }
  }
}

.boxNoTiMyRequest {
  height: 820px;
  .linkDetailTop {
    color: $color-nav;
    font-size: 14px;
    text-decoration: underline;
  }

  .requestInfoTop {
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;

    color: $color-silver;

    span {
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      align-items: center;
      color: $color-navy;
    }
  }
  hr {
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .requestInfo {
    height: 200px;
    overflow: auto;

    .itemRequest {
      margin-bottom: 5px;
    }
    .icon {
      width: 20px;
      height: 20px;
      overflow: hidden;
      margin-right: 5px;
      img {
        width: 100%;
        margin-top: -4px;
      }
    }
    .type {
      background: #ffe8ec;
      border-radius: 5px;
      font-weight: 500;
      font-size: 14px;
      color: $color-red;
      padding: 1px 5px;
      height: fit-content;
      margin-right: 5px;
    }
    .title {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: $color-navy;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .account {
      font-size: 12px;
      color: $color-blue4;
      padding: 2px 6px;
      background: $bg-plum;
      border-radius: 50px;
      margin-right: 5px;
    }
    .time {
      margin-top: 4px;
      font-size: 12px;
      color: $color-silver;
      @media (min-width: 1200px) and (max-width: 1400px) {
        display: block !important;
      }
    }
  }
  .requestInfo::-webkit-scrollbar {
    width: 6px;
  }
  .requestInfo.thin::-webkit-scrollbar {
    width: 2px;
  }
  .requestInfo::-webkit-scrollbar-track {
    border-radius: 10px;
    background: $color-borderTable;
  }
  .requestInfo::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $color-buttonSubmit;
  }
}

.boxAsset {
  height: 100%;
  position: relative;
  top: 0;
  left: 0;

  .detail {
    position: absolute;
    bottom: 15px;
    right: 15px;
  }
}

.boxTimeKeepingStatistical {
  height: 645px;
  position: relative;
  top: 0;
  left: 0;

  .detail {
    position: absolute;
    bottom: 15px;
    right: 15px;
  }
  @media screen and (max-width: 1500px) {
    height: 682px;
  }
}

.boxProject {
  padding-bottom: 30px !important;
  position: relative;
  top: 0;
  left: 0;

  .detail {
    position: absolute;
    bottom: 15px;
    right: 15px;
  }

  @media screen and (min-width: 900px) and (max-width: 1100px) {
    .projectStatus {
      display: flex;
      flex-wrap: wrap;
    }
    .itemProject {
      p {
        width: 100%;
        overflow: hidden;
        margin-bottom: 0 !important;
      }
    }

    .project {
      span {
        font-size: 22px !important;
        margin-left: 8px !important;
      }
    }

    :global {
      .title-body {
        margin-bottom: 0px;
        overflow: hidden;
        max-height: 30px;
      }
    }
  }

  hr {
    margin-top: 2px;
  }

  .effortTitle {
    font-weight: 500;
    color: #223354;
  }

  .projectInfoTop {
    .project,
    .effort {
      font-size: 14px;
      color: $color-silver;
    }
    .project {
      span {
        font-weight: 500;
        font-size: 30px;
        color: $color-red;
        margin-left: 10px;
      }
    }
    .effort {
      span {
        font-weight: 500;
        font-size: 30px;
        margin-left: 10px;
      }
    }
  }
  .listProject {
    max-height: 138px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;

    .itemProject {
      width: 50%;
      float: left;
      margin-bottom: 10px;

      &:nth-child(even) {
        padding-left: 5px;
      }

      &:nth-child(odd) {
        padding-right: 5px;
      }

      .projectName {
        font-size: 14px;
        line-height: 16px;
        text-transform: capitalize;
        color: #333333;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .effort {
        padding: 2px 8px;
        background: $bg-salmon;
        border-radius: 5px;

        font-size: 14px;
        font-weight: 500;
        color: $color-navy;
      }
    }
  }
  .projectStatus {
    .itemProject {
      width: 33.333%;
      float: left;
      font-size: 14px;
      color: $color-silver;
      margin-bottom: 10px;
      overflow: auto;

      span {
        font-weight: 500;
        font-size: 20px;

        color: $color-navy;
      }
    }
  }
}

.boxDailyReport {
  flex: 1;
  margin-top: 20px;
  position: relative;

  :global {
    .title-body {
      margin-bottom: 0px;
      max-height: 24px;
      overflow: hidden;
    }
  }

  .detail {
    position: absolute;
    bottom: 15px;
    right: 15px;

    @media screen and (max-width: 900px) {
      position: unset;
      margin-left: auto;
    }
  }

  .btnReport {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    padding: 10px;
    font-weight: 500;
    background-color: #fea628;
    border: none;
    box-shadow: none;
    text-shadow: none;

    &:hover,
    &:focus {
      background-color: #ffbd52;
    }
  }

  .listDailyReport {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    font-weight: 500 !important;
  }

  .success {
    background-color: #e1f5e0;
  }
  .reject {
    background-color: #ffe8ec;
  }
  .pending {
    background-color: #fff6e8;
  }

  .report {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 40px;
    padding: 5px 10px;
    width: 100%;
    border-radius: 5px;
    color: #223354;

    p {
      margin: 0 !important;
    }

    .reportName {
      flex: 1;
    }

    .action {
      min-width: 24px;
      display: flex;
      justify-content: center;
      align-items: center;

      .actionIcon {
        cursor: pointer;
        color: #8d8d8d;
      }
    }
  }
}

.boxAsset {
  .assetInfoTop {
    background: url('./../../../assets/img/icons/dashboard/bg_asset.svg') no-repeat;
    background-size: cover;
    padding: 10px 15px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;

    .total {
      font-size: 34px;
    }
  }
  .listAsset {
    margin-left: -15px;
    margin-right: -15px;

    h4 {
      font-weight: 500;
      font-size: 14px;
      color: $color-navy;
      padding-left: 15px;
    }
    .itemAsset {
      font-size: 14px;
      color: #333333;
      padding: 10px 0px 10px 15px;

      &:nth-child(odd) {
        background-color: #f9f9f9;
      }
    }
  }
}

.boxMonthlyEvaluation {
  height: 478px;
  position: relative;
  top: 0;
  left: 0;

  @media (min-width: 1360px) {
    height: 478px;
  }

  .detail {
    position: absolute;
    bottom: 15px;
    right: 15px;
  }
}

.boxEvent {
  .topTitle {
    @media (min-width: 1360px) {
      display: flex;
      justify-content: space-between;
    }
  }
  .infoTop {
    @media (min-width: 1360px) {
      display: flex;
    }
    .item {
      display: flex;
      justify-content: space-between;

      @media (max-width: 1360px) {
        width: 50%;
        float: left;
        margin-bottom: 5px;
      }
    }

    .textInfoTop {
      font-size: 14px;
      color: #333333;
    }
    .totalInfoTop {
      background: #c9eaff;
      border-radius: 5px;
      padding: 2px 10px;
      margin-left: 15px;
      margin-right: 30px;
      color: $color-navy;
      height: fit-content;
    }
    a {
      font-size: 14px;
      text-decoration-line: underline;
      color: $color-silver;
    }
  }
}

.listEvent {
  text-align: left;
  position: relative;
  top: 0;
  left: 0;

  .itemEvent {
    background: #ffffff;
    border: 1px solid #e7e7e7;
    box-sizing: border-box;
    box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);
    border-radius: 5px;
    padding-bottom: 10px;

    .image {
      border-radius: 4px 4px 0px 0px;
      height: 170px;
      overflow: hidden;

      img {
        width: 100%;
        object-fit: cover;
      }
    }
    .date {
      font-size: 14px;
      line-height: 22px;
      color: $color-silver;
      text-transform: uppercase;
      text-align: left;
      margin-top: 10px;
      padding-left: 10px;
    }
    .title {
      margin-top: 5px;
      text-align: left;
      font-size: 16px;
      font-weight: 500;
      color: $color-navy;
      padding-left: 10px;
      padding-right: 10px;
      min-height: 45px;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .register {
      margin-top: 5px;
      margin-bottom: 5px;
      text-align: left;
      font-size: 14px;
      color: #bdbdbd;
      padding-left: 10px;
    }
    .button {
      padding: 0px 10px;
      :global {
        button {
          width: 100%;

          background-color: $bg-salmon !important;
          color: $color-silver;

          &:hover {
            background-color: $color-yellow !important;
            color: white;
          }
        }
      }
    }
  }

  .nextButton {
    width: 25px;
    height: 26px;
    border: 1px solid $color-borderTable;
    border-radius: 100%;
    background-color: $bg-body;
    position: absolute;
    right: 3px;
    top: 21%;
    z-index: 1;

    img {
      width: 21px;
      height: 26px;
      padding: 7px;
    }
  }

  .nextButton:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .prevButton {
    width: 25px;
    height: 26px;
    border: 1px solid $color-borderTable;
    border-radius: 100%;
    background-color: $bg-body;
    position: absolute;
    left: 3px;
    top: 21%;
    z-index: 1;

    img {
      width: 21px;
      height: 26px;
      padding: 7px;
    }
  }

  .prevButton:hover {
    transform: scale(1.2);
    cursor: pointer;
  }
  :global {
    .swiper-slide {
      margin: 0px 7px !important;
    }
  }
}
.linkToWorkFlDetail {
  text-decoration: none !important;
}
.thankYouDashboard {
  background: url('./../../../assets/img/icons/thank-you-dashboard.png') no-repeat;
  background-size: 116% 166%;
  border-radius: 5px;
  height: 85px;
  object-fit: cover;
  padding: 15px 0;
  background-position-x: 50%;
  background-position-y: 36%;
  height: 100%;

  @media screen and (max-width: 1463px) {
    img {
      width: 40px;
    }
  }
  @media (min-width: 1205px) and (max-width: 1463px) {
    img {
      width: 35px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1205px) {
    img {
      width: 30px;
    }
  }
  .info {
    p {
      color: #223354;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      padding: 0;
      margin: 0;
      @media (min-width: 1463px) and (max-width: 1579px) {
        font-size: 16px;
      }
      @media (min-width: 1266px) and (max-width: 1463px) {
        font-size: 14px;
      }
      @media (min-width: 1200px) and (max-width: 1462px) {
        font-size: 13px;
      }
    }
    .thankYou {
      font-size: 20px;
      @media (min-width: 1463px) and (max-width: 1579px) {
        font-size: 18px;
      }
      @media (min-width: 1266px) and (max-width: 1463px) {
        font-size: 16px;
      }
      @media (min-width: 1200px) and (max-width: 1462px) {
        font-size: 15px;
      }
    }
    .dateOnboard {
      color: $color-black;
      font-weight: 100;
      font-size: 15px;
      @media (min-width: 1463px) and (max-width: 1579px) {
        font-size: 14px;
      }
      @media (min-width: 1266px) and (max-width: 1463px) {
        font-size: 13px;
      }
      @media (min-width: 1200px) and (max-width: 1462px) {
        font-size: 13px;
      }
    }
  }
}
.dayOff {
  display: flex;
  align-items: flex-start;
  padding: 16.75px 17.5px;
  border-radius: 5px;
  background: #ebf9ff;
  border: 1px solid $color-border-dayy-off;
  box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);
  border-radius: 5px;
  img {
    margin-right: 12.5px;
  }

  .label {
    color: $color-label-time-keeping;
  }

  .value {
    font-size: 20px;
    font-weight: 500;
    color: $color-label-time-keeping;
  }
}
.typeRequest {
  color: #223354;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 10px;
}
.dashboard {
  margin-top: 25px;

  .containerDashboard {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 146px 700px;
    gap: 16px;
  }

  @media screen and (max-width: 900px) {
    .containerDashboard {
      display: flex;
      flex-direction: column;
    }
  }
  :global {
    @media screen and (max-width: 390px) {
      .css-mhc70k-MuiGrid-root {
        .css-mhc70k-MuiGrid-root > .MuiGrid-item {
          min-width: 375px;
          padding-right: 16px;
        }
      }
    }
  }
}

.thankYou {
  padding-left: 20px;
}

.totalWork {
  width: 15px;
  height: 15px;
}

.totalWorkBlock {
  background-color: #ffdba9;
}

.commingSoon {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: #ffffff, $alpha: 0.5);
  & > span {
    font-size: 20px;
    line-height: 23px;
    font-weight: 500;
    color: #bdbdbd;
  }
}

.noticeDailyReport {
  border-radius: 5px;
  background: #ffe8ec;
  box-shadow: 0px 0px 10px 0px rgba(41, 41, 41, 0.15);
  margin-bottom: 10px;
  display: flex;
  padding: 6px 12px;
  align-items: center;
  gap: 8px;
  max-width: 100%;

  span {
    color: #e00;
    font-size: 16px;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.deleteBtn,
.editBtn {
  display: flex !important;
  align-items: center;
  gap: 8px;
  border: none !important;
  width: 100%;
  padding: 0 6px !important;
  border-radius: 4px !important;
  color: #333 !important;

  svg {
    color: #223354 !important;
  }

  &:hover,
  &:focus {
    background-color: #fff7e9 !important;
    color: #223354 !important;
  }

  svg {
    font-size: 20px;
  }

  span {
    font-weight: 500;
  }
}

.deleteBtn {
  height: 32px;
}

.disableMenu {
  display: none;
}

:global(.ant-dropdown-menu) {
  border-radius: 8px !important;
  padding: 4px !important;
  overflow: hidden;
}

:global(.ant-dropdown-menu-item) {
  padding: 2px 4px !important;

  &:hover {
    background-color: transparent !important;
  }
}

.hrMenu {
  margin: 0;
  background-color: #e7e7e7;
}
