@import "../../../../styles/variable.scss";
@import "../../../../../src/styles/mixin";

.calendar {
  .calendarHeader {
    border: 1px solid $color-wheat;
    border-radius: 4px;
    overflow: hidden;

    .top {
      display: flex;
      align-items: center;
      background-color: $bg-salmon;
      padding: 6px 17.5px;
      border-radius: 4px 4px 0 0;

      .month {
        color: $color-navy;
        font-weight: 500;
      }

      .preBtn,
      .nextBtn {
        background-color: $bg-white;
        width: 30px;
        height: 30px;
        min-width: 30px;
        margin: 0 2px;
      }

      @mixin icon {
        width: 13px;
        height: 13px;
        border-radius: 50%;
      }

      .fulltimeIcon {
        @include icon();
        background-color: $color-active;
      }

      .lateIcon {
        @include icon();
        background-color: $color-yellow2;
      }

      .loseIcon {
        @include icon();
        background-color: $color-switch-base;
      }

      .offIcon {
        @include icon();
        background-color: $color-red;
      }
    }

    .bottom {
      display: flex;
      background-color: $bg-white;

      .weekDay {
        padding: 4px;
        text-align: center;
        flex-basis: calc(100% / 7);
        border: 1px solid $color-wheat;
        font-weight: 500;
      }
    }
  }
  .holiday {
    position: absolute;
    left: 50px;
    top: 6px;
  }
  .paidLeaveIcon {
    position: absolute;
    right: 22px;
    height: 15px;
    top: 6px;
  }
  .otTimekeepingIcon {
    position: absolute;
    right: 22px;
    top: 6px;
  }
  .dots {
    position: absolute;
    right: 4px;
    top: 4px;
    &:hover {
      font-size: 18px;
      transition: font-size 0.1s;
      transition-timing-function: ease;
    }
  }
  .calendarBody {
    display: flex;
    flex-wrap: wrap;
    border-radius: 4px;
    margin-top: 6px;
    cursor: pointer;
    .date {
      width: 100px;
      height: 114px;
      flex-basis: calc(100% / 7);
      border: 1px solid $color-wheat;
      background-color: $bg-white;
      position: relative;
    }

    .normalDate {
      padding: 5px;
    }

    .disable {
      pointer-events: none;
      background-color: $bg-body;
      height: 100%;
    }

    @mixin border {
      width: 3px;
      height: 100%;
      margin-right: 5px;
    }

    .fulltime {
      background-color: $color-green1;
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      padding-right: 5px;

      .borderLeft {
        @include border();
        background-color: $color-active;
      }

      .content {
        flex-grow: 1;
      }
    }

    .lateTime {
      background-color: $bg-yellow3;
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      padding-right: 5px;

      .borderLeft {
        @include border();
        background-color: $color-yellow2;
      }

      .content {
        flex-grow: 1;
      }
    }

    .loseTime {
      background-color: $bg-magnolia;
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      padding-right: 5px;

      .borderLeft {
        @include border();
        background-color: $color-switch-base;
      }

      .content {
        flex-grow: 1;
      }
    }

    .offTime {
      background-color: $bg-pink1;
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      padding-right: 5px;

      .borderLeft {
        @include border();
        background-color: $color-red;
      }

      .content {
        flex-grow: 1;
      }
    }

    .full {
      color: $color-active1;
    }

    .late {
      color: $color-late;
    }

    .off {
      color: $color-red;
    }
  }
}
.suggestRequest {
  width: 300px;
  max-height: 450px;
  background: #ffffff;
  box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);
  border-radius: 4px;
}
.titleSuggestRequest {
  color: #223354 !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  margin: 0 0 0 0;
  padding: 6px 15px;
}
.requestItem {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 40px !important;
  color: #333333 !important;
  transition-timing-function: ease !important;
  &:hover {
    color: #223354 !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    transition: font-weight 0.3s, font-size 0.3s, color 0.3s !important;
  }
}
.standardShift {
  background: linear-gradient(91.34deg, $color-blue2 0%, #9c86f3 99.96%);
  box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);
  border-radius: 5px;
  height: 74px;
  margin-bottom: 10px;
  padding: 10px 0 0 20px;
  position: relative;
  @media (min-width: 1024px) and (max-width: 1200px) {
    padding: 10px 0 0 8px;
  }
  .backGround {
    position: absolute;
    right: 22px;
    right: 0;
    top: 0;
  }
  .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $bg-tooltip;
    margin: 0 0 8px 0;
  }
  .time {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: $bg-tooltip;
    @media (min-width: 1024px) and (max-width: 1170px) {
      margin-top: 20px;
    }
    .afterNoon {
      margin-left: 30%;
      @media (min-width: 1250px) and (max-width: 1600px) {
        margin-left: 10%;
      }
      @media (min-width: 1024px) and (max-width: 1250px) {
        margin-left: 5%;
      }
    }
    img {
      margin: -2px 6px 0 0;
    }
  }
}

.dayOff {
  display: flex;
  align-items: flex-start;
  padding: 16.75px 17.5px;
  border-radius: 5px;
  margin-bottom: 10px;
  background: #ebf9ff;
  border: 1px solid $color-border-dayy-off;
  box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);
  border-radius: 5px;
  img {
    margin-right: 12.5px;
  }

  .label {
    color: $color-label-time-keeping;
  }

  .value {
    font-size: 20px;
    font-weight: 500;
    color: $color-label-time-keeping;
  }
}

.timeSheet {
  background-color: $bg-white;
  border: 1px solid $color-wheat;
  border-radius: 5px;
  padding: 0 15px;
  box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);

  .info {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $color-wheat;
    padding: 15px 0;

    img {
      margin-right: 20px;
      width: 70px;
      height: 70px;
      object-fit: cover;
      border-radius: 50%;
    }

    .name {
      color: $color-navy;
      font-size: 20px;
      font-weight: 500;
    }

    .position {
      color: $color-nav;
    }
  }
}

.allTime {
  padding: 15px 0 10px 0;
}

.timeSheetItem {
  display: flex;
  justify-content: space-between;
  padding: 9px 17.5px;
  background-color: $bg-plum;
  margin-bottom: 5px;
  border-radius: 5px;

  .item {
    display: flex;

    img {
      margin-right: 5px;
    }
  }
  .label {
    color: $color-navy;
  }
  .value {
    color: $color-navy;
    font-weight: 500;
  }
}

.special {
  background-color: $color-yellow4;
}

.totalTimeSheet {
  margin: -1.5rem;

  .info {
    padding: 20px;
    background-color: $bg-salmon;

    .name {
      font-size: 20px;
      font-weight: 500;
    }

    .email {
      color: $color-nav;
    }
  }

  .totalTimeSheetBody {
    padding: 0 20px;

    .field {
      border-bottom: 1px solid $color-wheat;
      padding: 12px 0;

      .label {
        color: $color-navy;
        font-weight: 500;
      }

      .value {
        color: $color-charcoal;
      }
    }
  }

  .otherInfo {
    .title {
      color: $color-navy;
      margin: 12px 0;
      font-size: 16px;
      font-weight: 500;
    }

    .otherInfoItem {
      margin-bottom: 12px;

      .itemName {
        font-weight: 500;
        color: $color-charcoal;
        margin-right: 3px;
      }

      .total {
        color: $color-charcoal;
        margin-right: 15px;
        font-weight: 500;
      }

      .label {
        color: $color-navy;
        font-weight: 500;
      }

      .value {
        color: $color-nav;
      }
    }

    .waitStatus {
      color: $color-yellow2;
    }

    .approvedStatus {
      color: $color-active;
    }

    .rejectedStatus {
      color: $color-red;
    }
  }
}

.popover {
  background-color: $bg-plum;
  padding: 16px;

  .title {
    font-size: 16px;
    color: $color-navy;
  }

  .request {
    padding: 5px 0;

    &:not(:last-child) {
      border-bottom: 1px solid $color-wheat;
    }

    .value {
      color: $color-navy;
      font-weight: 500;
    }

    .status {
      font-weight: 500;
      color: $color-blue1;
    }
  }
}

.listRequest {
  height: 84px;
  overflow: auto;
  overflow-x: hidden;
  .paidLeave {
    background: $color-active;
  }
  .unpaidLeave {
    background-color: #e74c3c;
  }
  .ot {
    background-color: #0057ff;
  }
  .goEarlyComeLate {
    background-color: #ff00c7;
  }
  .remote {
    background-color: #eb996e;
  }
  .addtionalWork {
    background-color: #00b2ff;
  }
  .notAccept {
    background: #bdbdbd;
  }
  .bdPaidLeave {
    border: 1px solid $color-active;
  }
  .bdUnpaidLeave {
    border: 1px solid #e74c3c;
  }
  .bdOt {
    border: 1px solid #0057ff;
  }
  .bdGoEarlyComeLate {
    border: 1px solid #ff00c7;
  }
  .bdRemote {
    border: 1px solid #eb996e;
  }
  .bdAddtionalWork {
    border: 1px solid #00b2ff;
  }
  .itemRequest {
    border-radius: 5px;
    margin-bottom: 5px;
    padding: 2px;
    display: flex;
    .avatarRequest {
      width: 20px;
      height: 20px;
      object-fit: cover;
      border-radius: 50%;
      background: $color-white;
    }
    span {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 160px;
      margin-left: 6px;
    }
  }
}

.listRequest::-webkit-scrollbar {
  width: 2px;
}

.listRequest.thin::-webkit-scrollbar {
  width: 2px;
}

.listRequest::-webkit-scrollbar-track {
  border-radius: 10px;
  background: $color-white;
}

.listRequest::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: $bg-yellow1;
}

.modalDetail {
  :global {
    .MuiPaper-root {
      min-width: 1000px;
      .modal-body {
        padding: 0 !important;
      }
    }
  }
  .totalTimeSheetRequest {
    width: 65%;
  }
  .layout {
    display: flex;
  }
  .otherInfoRequest {
    width: 35%;
    padding: 20px;
    background: #e9ebee;
    .title {
      color: #223354;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
    .blockUser {
      display: flex;
    }
    .avatarRequestOther {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 50%;
      background: $color-white;
      margin-right: 15px;
    }
    .topMg {
      margin-top: 30px;
    }
    .timeline {
      margin-left: -160px;
      p {
        margin: 0;
      }
      .titleTimeLine {
        color: #223354;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
      }
      .typeTimeLine {
        color: #223354;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 8px;
      }
    }
    .date {
      margin: 0;
      color: #223354;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }
    .shift {
      margin: 0;
      color: #8d8d8d;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
    }
  }
  .head {
    background: #f9fbfc;
    height: 100px;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    .action {
      display: flex;
    }
    .titleType {
      margin: 0 0 8px 0;
      color: #223354;
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
    }
    .statusType {
      margin: 0;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #223354;
    }
  }
  .body {
    padding: 20px;
    .title {
      color: #223354;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
    .textNormal {
      color: #333333;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }
    .textSpec {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .blockRemote {
      display: flex;
    }
    .stt {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 14px;
      color: #223354;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }
    .date {
      margin: 0;
      color: #223354;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }
    .shift {
      margin: 0;
      color: #8d8d8d;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
    }
  }
}
.short {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.userAccept {
  width: 60%;
}
.actionRequest {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  .actionBt {
    width: 48%;
  }
}

.itemInfor {
  margin-bottom: 18px;
}

.itemInfor:last-child {
  margin-bottom: 0px;
}

.minWidth1200 {
  @include min-width-1200;
}
.center {
  margin: 0 auto;
}
.filterMonth {
  display: flex;
  justify-content: center;
  .month {
    color: #223354;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }
}
:global {
  .MuiSvgIcon-root {
    font-size: 25px !important;
  }
}

.waitStatus {
  color: $color-blue1;
}

.approvedStatus {
  color: $color-active;
}

.rejectedStatus {
  color: $color-deny;
}

.openStatus {
  color: $color-status-open;
}

.reworkStatus {
  color: $color-rework;
}

.recallStatus {
  color: $color-status-removed;
}
